/** New Service Worker registration 
*   Vue Composition API hook Module
* 
*   Author: Norbert Dries
*   Last Change Date: 09.08.2021
*
*   Statemachine GOF state pattern used
*   Commands depends on state (main-state and sub-state) GOF command-pattern used
*
*/

import { ref } from "vue"

import { Resource, ResourceText } from "@/infrastructure/resource/resource";
import { ServiceWorkerEvent } from "@/business/application/AppInterfaces"
import { openDialog , ModalDialogType } from "@/infrastructure/observables/ModalDialogNotification"
import { ErrorLog } from "@/business/error/ErrorLog"
import { LightLog, LogTyp } from "@/business/log/LightLog"
import { newDate } from "@/infrastructure/functional/datetimehelper";
import {  navigateToLastPage, setViewState } from "./AppNavigationAfterUpdate";
import { VersionControl } from "./VersionControl";
import { ReorganizeDatabaseAfterChange } from "./ReorganizeDatabaseAfterChange";
import { Out } from "../frontent/clientMessage";


export default function handleAppChange(store: any) {
  const modulName = "handleAppChange"  
  const refreshing = ref(false)  
  const updateExists = ref(false)
  const shouldRefresh = ref(false)
  const lastUpdate = newDate()

  let worker: ServiceWorkerRegistration

  //__ interact with vue router-listener in App.vue
  //__ ensures that windows.reload ist not to early invoked
  function isRefresh() {
    const temp = shouldRefresh.value
    shouldRefresh.value = false
    return temp
  }


  //__ shows an update dialog to user. This functionality is automaticly triggered on an update
  // function showUpdateDialog(event: any) { 
  //   if ( (event as ServiceWorkerEvent).detail === undefined ) {
  //     ErrorLog.add(modulName , new Error(Resource.getResourceUpdateAppText(ResourceText.ServiceWorkerRegisterUndefined)), true , true)   
  //   }
  //   const dt = newDate()

  //   if ( Math.abs( dt.getTime() - lastUpdate.getTime() ) > 120 ) {
  //     updateExists.value = false
  //   }

  //   if (! updateExists.value)  {
  //     updateExists.value = true          
  //     openDialog(store,{name: Resource.getResourceUpdateAppText(ResourceText.UpdateModalDialogName) , isCancel: true, isOk: true, open: true, titel: Resource.getResourceUpdateAppText(ResourceText.UpdateAppVersionTitle), text: Resource.getResourceUpdateAppText(ResourceText.UpdateAppVersionText), type: ModalDialogType.VersionUpdate})
  //   }
  // }

  //__ puts the new service from waiting state into working state
  function activateServiceWorker() {
    try {
      updateExists.value = false    
      if (!worker || !worker.waiting) {
        controllerChanged()
        return
      }

      worker.waiting.postMessage({ type: 'SKIP_WAITING' })  
      } catch (error) {
        controllerChanged()
    }
  }

  async function setLastViewState( store: any, routeName: string ) : Promise<void> {    
    await setViewState(store, routeName)
  }

  function setWorker(event: any) { 
    try {
      if ( (event as ServiceWorkerEvent).detail) {
        worker = event.detail
      }  
    } catch (error) { 
      Out.noOperation
    }
  }


  async function navigateAfterActivation() {
    if (refreshing.value) return
    refreshing.value = true    
    const result = await navigateToLastPage()
    if ( result) shouldRefresh.value = true
  }

  async function controllerChanged() {
    const version = new VersionControl()
    if (! await version.isNewVersionPending() ) { 
      return
    }

    await ReorganizeDatabaseAfterChange.updateToNewVersion()
    await navigateAfterActivation()
}

  async function updatedServiceWorker() {
    const version = new VersionControl()
    await version.serviceWorkerUpdateInvoked()
    console.log("swUpdate updatedServiceWorker")
  }


  if ( navigator && 'serviceWorker' in navigator) {
    //__ receives update possibilty from injected service worker
    document.addEventListener('swUpdated', (event: any) => {
      setWorker(event)
      updatedServiceWorker()
    }, { once: true })

    


    navigator.serviceWorker.addEventListener('controllerchange', (event: any) => {      
      controllerChanged()       
  })  


    
  }

  return {
    activateServiceWorker,
    isRefresh,
    setLastViewState,
  }
}
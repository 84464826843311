import { AppConst } from "@/business/AppConst";
import { StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import { StateFishing } from "@/business/application/statemachine/concrete/StateFishing";
import { StateHome } from "@/business/application/statemachine/concrete/StateHome";
import { StateHunting } from "@/business/application/statemachine/concrete/StateHunting";
import { StateLogin } from "@/business/application/statemachine/concrete/StateLogin";
import { RoutingConstants } from "@/business/application/statemachine/constants/RoutingConstants";
import { StateConstants, StringToStateConstants } from "@/business/application/statemachine/constants/StateConstants";
import { ErrorLog } from "@/business/error/ErrorLog";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";
import store from "@/store";
import { LoaderStateServiceForRoot } from "../observables/loaderState";


  //__ state possibilities (re-entry) for Version handling
export interface StateCondition {
    State: StateHome|StateLogin|StateFishing|StateHunting,
    condition:string
  }

const modulName = "AppNavigationAfterUpdate"  
const conditionName = "*"
const  conditionValue = "UpdateApp"

const routeStateMapping = new Map<string,string>(
  [
    [RoutingConstants.Login, StateConstants.Login],
    [RoutingConstants.Home, StateConstants.Home],
    [RoutingConstants.Hunting, StateConstants.Hunting],
    [RoutingConstants.Fishing, StateConstants.Fishing],
  ])


  const newStateConditionMapping = new Map<string, StateCondition>(
      [
        [StateConstants.Login, {State: new StateLogin(),condition: conditionValue }],
        [StateConstants.Home, {State: new StateHome(),condition: conditionValue }],
        [StateConstants.Fishing, {State: new StateFishing(),condition: conditionValue }],
        [StateConstants.Hunting, {State: new StateHunting(),condition: conditionValue }],
      ])
    
  function getStateConditionMapping(state: StateConstants) : StateCondition {
        return newStateConditionMapping.get(state) ??  {State: new StateLogin(),condition: conditionValue };
    }

  //__ the whole app is always in a certain state. State und routename is a one to one relation
  function getMainStateFromRouteName( routeName: string ) : string {
    return routeStateMapping.get(routeName) ?? StateConstants.Login
  }

  //__ the app has four re-entry possibilities only: Login, Home, Hunting and Fishing
  export async function setViewState( store: any, routeName: string ) : Promise<void> {    
    try {      
      const userProfile: UserProfile = new UserProfile()      
      await userProfile.ChangeLastMainState(getMainStateFromRouteName( routeName ))
    }
    catch (e) {
      ErrorLog.add(modulName , e , true, true)            
    }
  }    


   //__ state handling includes state changes in vuex and at least index db store
   export async function navigateToLastPage() : Promise<boolean> {
    try {
      const stateMachine = StateMachineServiceLocator.get();
      const userProfile: UserProfile = new UserProfile()      
      const userProfileData = await userProfile.GetProfileData()

      const state = StringToStateConstants(userProfileData.lastMainViewMainState)
      const lastState = getStateConditionMapping(state)

      let condition = [{ nameId: conditionName, value: lastState.condition }]
      stateMachine.setAppUpdateState(lastState.State);

      if ( ! userProfileData.online && userProfileData.lastMainViewMainState === StateConstants.Login ) {
        if ( userProfileData.lastLogin > new Date(AppConst.MinDate)) {
          stateMachine.setAppUpdateState(new StateHome());
          condition = [{ nameId: conditionName, value: conditionValue}]
        } else {
          stateMachine.setAppUpdateState(new StateLogin());
          condition = [{ nameId: conditionName, value: conditionValue }]
        }
      }


      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: condition,
        },
        { lookahead: false }
      );
      changeNavigation(store);
    } catch (e) {
      ErrorLog.add(modulName , e , true, true)           
      return false    
    }    

    const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
    loaderState.changeLoaderState({isInProgress: false})


    return true
    
 
  } 




   //__ state handling includes state changes in vuex and at least index db store
   export async function navigateToLoginX() : Promise<boolean> {
    try {
      const stateMachine = StateMachineServiceLocator.get();
      const userProfile: UserProfile = new UserProfile()      
      const userProfileData = await userProfile.GetProfileData()

      stateMachine.setAppUpdateState(new StateLogin());
      const condition = [{ nameId: "Login", value: "Login" }]

      await setViewState(store, "Login");

      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: condition,
        },
        { lookahead: false }
      );
      changeNavigation(store);


    } catch (e) {
      ErrorLog.add(modulName , e , true, true)           
      return false    
    }    

    return true
    
 
  } 

import { MinDbVersion } from '@/business/AppConst';
import { getRealDbVersion, getRecord } from '../database/asyncDb';

export class AsyncDbVersion {

    private static isUpdateDatabaseVersion = false  
    private static currentDbVersion = MinDbVersion

    public static setUpdateDatabaseVersion( value: boolean) {
        AsyncDbVersion.isUpdateDatabaseVersion = value
    }

    public static async ensureCurrentDbVersion() {
        AsyncDbVersion.currentDbVersion = await getRealDbVersion()
    }    

    public static async getDbVersion() {
        if ( AsyncDbVersion.isUpdateDatabaseVersion ) {            
            return AsyncDbVersion.currentDbVersion + 1
        }
        return AsyncDbVersion.currentDbVersion       
    }    
 }



import { StringToStateConstants } from '@/business/application/statemachine/constants/StateConstants';
import { Out } from '@/infrastructure/frontent/clientMessage';
import { ComboBoxDataSource } from './ComboBoxDataSource';
import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { NamedListItem, ObjectListItems } from "@/infrastructure/generics/namedListItem";
import { Identity } from '@/infrastructure/backend/userIdentity';
import { StateMachineServiceLocator } from '../../../business/application/statemachine/StateMachine';
import { StateResult } from '@/business/application/statemachine/StateInterfaces';
import { OnlineSearchDataSource } from "@/components/Shared/Dynamicform/OnlineSearchDataSource"
import { ObjectSearchDataSource } from './ObjectSearchDataSource';
import { RuleLoadFishing } from '@/business/application/commands/Concrete/RuleLoadFishing';
import { Resource } from '@/infrastructure/resource/resource';
import { getOfflineForm } from '@/infrastructure/serviceworker-related/OfflineFormServiceWorker';
import { DynamicFormBusinessCases } from './DynamicFormBusinessCases';
import { ConvertDateToDayString, ConvertDateToIsoString, ConvertUtcStringToDate, ConvertUtcStringToDateOrUndefined, GenerateCorrectTime, getCurrentDayDate, getDateFromGermanStringDate, getDateWithoutTime, makeValidUtcString, newDate, overwriteSerializedLocalDate } from '@/infrastructure/functional/datetimehelper';
import { DynamicGridRowParentChild } from '../dynamicList/DynamicListTypes';
import { FishingEdit } from '@/components/fishing/business/FishingEdit';
import { GeoState, GeoStateAction, GeoView } from '../geo/hooks/ChangeGeoMap';
import { GeoStoreData } from '../geo/hooks/GeoStoreData';
import { DynamicListStore } from '../dynamicList/DynamicListStore';
import { DynamicLoadFromListData } from './DynamicLoadFromListData';
import { RemoteProdukt } from '@/components/webshop/RemoteProdukt';
import { BusinessLogicJahresWechsel } from '@/components/webshop/BusinessLogicJahresWechsel';
import { RefreshServerObjects } from '@/infrastructure/resource/RefreshServerObjects';
import { Navigate } from '@/infrastructure/backend/async/Navigate';
import { NotificationStateServiceForRoot } from '@/infrastructure/observables/notficationState';
import { VersionControl, appVersionControl } from '@/infrastructure/version/VersionControl';
import { version } from 'vue';
import { CodeVersion } from '@/business/AppConst';

export interface DynamicRecord {
  feldTitel?: string
  teilFormularName: string;
  formularFeldname: string;
  feldTyp: string;
  istEditierbar: boolean;
  istPflichtfeld: boolean;
  istSichtbar: boolean;
  maxLaenge: number;
  resourceId: string;
  teilFormularReihenfolge: number;
  regulaererAusdruck: string;
  resourceIdErrorMeldungPflichtfeld: string;
  resourceIdErrorMeldungValidierung: string;
  resourceIdErrorMeldungMaxLaenge: string;
  layoutKlasse: string;
  isValidationError: boolean;
  validationMessage: string;
  value: any;
  dateValue?: Date
  tabellenFeldname: string;
  tabellenName: string;
  namedValueListDataSourceName?: string
  namedValueList?: Array<NamedListItem> | Array<ObjectListItems> | undefined
  namedValueListOrigin?: Array<NamedListItem> | Array<ObjectListItems> | undefined
  nameValueListIsEdit?: boolean
  namedValueListIsNullable?: boolean
  namedValueListFilterTyp?: string
  namedValueListDataSourceTyp?: string
  namedValueListStartSearchAt?: number
  list?: any,
  infoTextTitle: string | undefined
  infoTextContent: string | undefined
  infoResourceId: string | undefined
  parentCascadeId: string | undefined
  kategorie?: string | undefined
  resourceIdServerValidation?: string |  undefined
}

export interface DynamicSaveRecord {
  formularFeldname: string;
  value: string;
}

export interface TempTaskResult {
  isSucceeded: boolean;
  errorReason: string;
}

export interface DynamicDataSet {
  id?: string;
  language?: string;
  viewId: string;
  teilFormularName?: string;
  state: string;
  stateMessage: string;
  apiLoadMethod?: string;
  apiSaveMethod?: string; 
  view: DynamicRecord[];
  tempTaskResult?: TempTaskResult;
  apiEntryLoadMethod?:  string,    
}

export interface StoreDataSet {
  id: string;
  view: DynamicRecord[];
}

export interface RemoteStoreDataSet {
  id: string;
  view: DynamicSaveRecord[];
} 

export interface RemoteFormRequest {
  formName: string
}

export interface ResponseDataSet {
  id: string;
  state: string;
  stateMessage: string;
  processState: string;
  processStateMessage: string;
  isError: boolean;
  dynamicGridRowParentChild?: DynamicGridRowParentChild
}

export class DynamicFormData {  
  private _store: any;
  public clientRouteName: string
  private search: OnlineSearchDataSource
  private objectSearchDataSource: ObjectSearchDataSource
  private parentCascadeId : string 
  private kategorie: string
  private businessCases: DynamicFormBusinessCases = new DynamicFormBusinessCases()
  private geoViewActionService: GeoView


 
  // public static async clientVersionNumber() {
  //   const version = new VersionControl()
  //   const result = await version.getDisplayVersion()
  //   return result
  // } 
  
  public constructor(clientRouteName: string, store: any) {
    // if (process.env.VUE_APP_VERSION) {
    //   DynamicFormData.clientVersionNumber = process.env.VUE_APP_VERSION
    // }
    this._store = store
    this.clientRouteName = clientRouteName
    this.search = new OnlineSearchDataSource()
    this.objectSearchDataSource = new ObjectSearchDataSource()
    this.parentCascadeId = ""
    this.kategorie = ""
    this.geoViewActionService = GeoView.getService()
  }



  public clearForm(): void {
    if ( this._store === undefined  || this._store === null) return
    const formData = this._store.getters["dynamicformstore/getDataSet"];
    const view: DynamicRecord [] = formData.view
    if ( view === undefined || view === null) return
    view.every(row => {
      if (row.feldTyp === "Text" || row.feldTyp === "Date" || row.feldTyp.indexOf("Check") >= 0 || row.feldTyp.indexOf("ValueCheck") >= 0 || row.feldTyp.indexOf("Combo") >= 0   ) {
        row.value = ''
      }
    })
    this._store.commit("dynamicformstore/addDataSet", formData);
  }

  public isLocalPage(pageName: string): boolean {
    try {
      const formData = this.getLocalForm();
      const temp: DynamicDataSet = {
        id: formData.id,
        language: UserProfile.GetClientSettingsSync().language,
        viewId: formData.viewId,
        teilFormularName: formData.teilFormularName,
        state: formData.state,
        stateMessage: formData.stateMessage,
        apiLoadMethod: formData.apiLoadMethod,
        apiSaveMethod: formData.apiSaveMethod,
        view: []
      };
      temp.view = formData.view.filter(row => {
        return row.teilFormularName === pageName;
      });
      return temp.view.length > 0;
    } catch (e) {
      return false;
    }
  }

  public getLocalPage(pageName: string): DynamicDataSet {
    const formData = this.getLocalForm();   
    const temp: DynamicDataSet = {
      id: formData.id,
      language: UserProfile.GetClientSettingsSync().language,
      viewId: formData.viewId,
      teilFormularName: pageName,
      state: formData.state,
      stateMessage: formData.stateMessage,
      apiLoadMethod: formData.apiLoadMethod,
      apiSaveMethod: formData.apiSaveMethod,
      view: []
    };
    temp.view = formData.view.filter(row => {
      if ( row.feldTyp === "CheckTextRight" && ( row.value === undefined || row.value === '' ))  {
        row.value = 'false'
      }
      if ( row.feldTyp === "CheckTextRightFix" && ( row.value === undefined || row.value === '' ))  {
        row.value = 'true'
      }
      if ( row.feldTyp === "ValueCheck" && ( row.value === undefined || row.value === '' ))  {
        row.value = 'false'
      }

      
      return row.teilFormularName === pageName;
    });
    return temp;
  }

  public getLocalForm(): DynamicDataSet {
    return this._store.getters["dynamicformstore/getDataSet"];
  }


    //**Load*****************************************Business Logik wegen Offline Forms einbauen **********************************************************

  private stateMachine = StateMachineServiceLocator.get();
  public async getRemoteForm( apiLoadMethod: string, dataView: string, clientRouteName: string, id: string, result: DynamicRecord[] | undefined): Promise<DynamicDataSet | null> {    

    let isGeoDone = false
    const lastState: StateResult = this.stateMachine.getCurrentResultState()
    if ( lastState.isBackWard && dataView !== "WebShop" ) {
     
        if (this.checkBackward(lastState)) {
          const dataSet = this.getLocalForm()
          await this.reloadGeoData(dataSet)

          return dataSet
        }
    }

    const formData = await this.getFormFromApi(apiLoadMethod, dataView, clientRouteName, id, result);
    if (formData === null) return null
    isGeoDone = await this.assignNamedValueLists(formData)


    if (dataView === "WebShop") {
      const gridRow = DynamicListStore.GetSelectedGridRow()
      if (gridRow && gridRow.detailForm && gridRow.detailForm.view && gridRow.detailForm.view.length > 0) {
        for( const targetRow of formData.view ) {
          for( const sourceRow of gridRow.detailForm.view) {
            if (sourceRow.value && sourceRow.value.length > 0) {
              if( targetRow.formularFeldname === sourceRow.formularFeldname ) {
                if (targetRow.feldTyp === "Date") {
                  try { 
                    targetRow.value = GenerateCorrectTime( ConvertUtcStringToDate(sourceRow.value))
                  
                  } catch(e) {
                    Out.error(sourceRow.value)
                  }
                 
                } else {
                  if (targetRow.feldTyp === "ValueCheck" ) {
                      targetRow.resourceId = sourceRow.resourceId;
                  }
                  targetRow.value = sourceRow.value
                }
                
              }
            }
            if( targetRow.formularFeldname === sourceRow.formularFeldname ) {
              targetRow.istPflichtfeld = sourceRow.istPflichtfeld;
              targetRow.istEditierbar = sourceRow.istEditierbar;
              targetRow.istSichtbar = sourceRow.istSichtbar;
            }

            if (targetRow.formularFeldname === "GueltigAb" && targetRow.formularFeldname === sourceRow.formularFeldname && sourceRow.value && sourceRow.value.length > 0) {
              targetRow.value = sourceRow.value
              targetRow.dateValue  = ConvertUtcStringToDate(sourceRow.value)
              targetRow.istPflichtfeld = sourceRow.istPflichtfeld;
              targetRow.istEditierbar = sourceRow.istEditierbar;
              targetRow.istSichtbar = sourceRow.istSichtbar;
            }

            if (targetRow.formularFeldname === "GueltigBis" && targetRow.formularFeldname === sourceRow.formularFeldname && sourceRow.value && sourceRow.value.length > 0) {
              targetRow.value = sourceRow.value
              targetRow.dateValue  = ConvertUtcStringToDate(sourceRow.value)
              targetRow.istPflichtfeld = sourceRow.istPflichtfeld;
              targetRow.istEditierbar = sourceRow.istEditierbar;
              targetRow.istSichtbar = sourceRow.istSichtbar;
            }
          }
        }
      }
    }

    if(dataView && dataView.indexOf("WildbuchEintrag") >= 0) {

      if( formData && formData.view && formData.view.length > 0) {
        for(const row of formData.view) {
          if (row.formularFeldname === "Datum" && (!row.value || row.value === "")) {
            row.value = getCurrentDayDate()
            row.dateValue  = getCurrentDayDate()
          }
        }
      }

     
    }

    await this.businessCases.getRemoteFormOverwriteFormWithBusinessCase(formData, clientRouteName)
    DynamicFormBusinessCases.handleNachsucheOnLoad(formData.viewId, formData.view)


    this.saveLocalForm(formData as DynamicDataSet);

    if ( ! isGeoDone && (clientRouteName === "WildBookEntry" || clientRouteName === "WildBookFall" || clientRouteName === "WildBookObserve" || clientRouteName === "WildBookObserveSpec")) {
      const state: GeoState = {
        action: GeoStateAction.SetPositionOnly,
        wgs84X: undefined,
        wgs84Y: undefined,
        revierNumber: undefined,
        typ: "jagdrevier",
        lastState: false,
        isEditable: undefined
      }
      this.geoViewActionService.changeGeoState(state)
    }

    return formData as DynamicDataSet;
  }

  public async getDynamicForm(formData: DynamicDataSet, clientRouteName: string | undefined) : Promise<DynamicDataSet> {    
    let isGeoDone = false
    isGeoDone = await this.assignNamedValueLists(formData)
    formData = await this.CorrectFormOnFormLoad(formData)  

    await this.businessCases.getRemoteFormOverwriteFormWithBusinessCase(formData, clientRouteName ?? "" )
    DynamicFormBusinessCases.handleNachsucheOnLoad(formData.viewId, formData.view)

    this.saveLocalForm(formData as DynamicDataSet);

    if ( !clientRouteName || clientRouteName === "") {
      return formData as DynamicDataSet
    }

    if ( ! isGeoDone && (clientRouteName === "WildBookEntry" || clientRouteName === "WildBookFall" || clientRouteName === "WildBookObserve" || clientRouteName === "WildBookObserveSpec")) {
      const state: GeoState = {
        action: GeoStateAction.SetPositionOnly,
        wgs84X: undefined,
        wgs84Y: undefined,
        revierNumber: undefined,
        typ: "jagdrevier",
        lastState: false,
        isEditable: undefined
      }
      this.geoViewActionService.changeGeoState(state)
    }

    return formData as DynamicDataSet;
  }



  private checkBackward( lastState: StateResult) : boolean {
    if ( lastState === undefined) 
      return true

    if ( lastState.newMainState === undefined || lastState.newState === undefined)
      return true

      if ( lastState.newMainState === "Fishing" && lastState.newState === "Fishing") {
        return false
      }

    return true
  }

  public async reloadGeoData(formData: DynamicDataSet ) : Promise<void> {
    let x: number | undefined = undefined
    let y: number | undefined = undefined
    for (const row of  formData.view) {
      if (row.formularFeldname === "KoordinateX") {
        if ( row.value)
          try {
            x = parseInt(row.value)
          } catch(e) {
            Out.noOperation("")
          }
      }
      if (row.formularFeldname === "KoordinateY") {
        if ( row.value)
        try {
          y = parseInt(row.value)
        } catch(e) {
          Out.noOperation("")
        }
      }
    }

    for (const row of  formData.view) {
      if (row.feldTyp === "ObjectComboBox" && row.namedValueListDataSourceName !== undefined && row.namedValueListDataSourceName.length > 0 ) {
        if (row.namedValueListDataSourceName === "JagdRevier" || row.namedValueListDataSourceName === "Jagdrevier" ) {
          if( row.namedValueList && row.namedValueList.length > 0 ) {
            if ( row.value && row.value.key) {
              const state: GeoState = {
                action: GeoStateAction.ChangeData,
                wgs84X: x,
                wgs84Y: y,
                revierNumber: row.value.key,
                typ: "jagdrevier",
                lastState: true,
                isEditable: undefined
              }              
              this.geoViewActionService.changeGeoState(state)             
           }
           else {
            const state: GeoState = {
              action: GeoStateAction.ChangeData,
              wgs84X: x,
              wgs84Y: y,
              revierNumber: undefined,
              typ: "jagdrevier",
              lastState: true,
              isEditable: undefined
              }             
              this.geoViewActionService.changeGeoState(state)   
            }
          } else {
            const state: GeoState = {
              action: GeoStateAction.ChangeData,
              wgs84X: x,
              wgs84Y: y,
              revierNumber: undefined,
              typ: "jagdrevier",
              lastState: true,
              isEditable: undefined
              }             
              this.geoViewActionService.changeGeoState(state)   
            }
        }
      }
    }
  }


  public async assignNamedValueLists( formData: DynamicDataSet ) : Promise<boolean> {
      let isGeoDone = false
      const editState = await FishingEdit.getEditFishingStateAsync()
      let x: number | undefined = undefined
      let y: number | undefined = undefined
      let newForm = true
      for (const row of  formData.view) {
        if ( row.formularFeldname && row.formularFeldname === "KoordinateX") {
          if (row.value )
            x = parseInt(row.value)
        }
        if ( row.formularFeldname && row.formularFeldname === "KoordinateY") {
          if (row.value )
            y = parseInt(row.value)
        }
        if ( row.value  ) {
          if ( typeof(row.value) === typeof("string")) {
            if ( row.value.length > 0) 
              newForm = false
          }
        }
      }

     
      for (const row of  formData.view) {
        if(row.feldTyp && row.feldTyp === "CheckTextRightFix") {
          row.value = "true"
        }
      }


      for (const row of  formData.view) {
        if(row.formularFeldname === "ReferenzDatumFilter") {
          row.value = "false"
          row.istEditierbar = false
          const jahreswechsel = await RemoteProdukt.getJahresWechsel("DynamicFormData")
          const businessJahresWechsel = new BusinessLogicJahresWechsel(jahreswechsel)
          if ( businessJahresWechsel.isNextYear()) {
            row.istEditierbar = true
            row.value = "true"
          }
        
        }


        if( row.feldTyp === "Date" && row.value) {
          try {
            row.dateValue = GenerateCorrectTime( new Date(makeValidUtcString(row.value)))
          } catch (error) {
            Out.error(error)
          }
        }        

        if ( row.feldTyp === "RemoteComboBox"  || row.formularFeldname === "GueltigKeitsListe"  ) {
          const listLoadData = DynamicLoadFromListData.getDynamicData()
          if ( listLoadData ) {

            let targetRow: DynamicRecord | undefined = undefined
            for(const sourceRow of listLoadData.records) {
              if (sourceRow.formularFeldname && sourceRow.formularFeldname === "GueltigKeitsListe") {
                targetRow = sourceRow
              }
            }

            if (targetRow && targetRow.value && typeof(targetRow.value) === "string" && targetRow.value.length > 0  ) {
              row.istEditierbar = true
              row.istSichtbar = true
              row.istPflichtfeld = true
              row.namedValueListOrigin = [] as NamedListItem[]
              const tempArray = targetRow.value.split(";")
              if ( tempArray && tempArray.length > 0) {
                const tempItem: NamedListItem = {
                  key: '0',
                  value: tempArray[0]
                }
                row.namedValueListOrigin.push(tempItem)
              }
             

              if ( tempArray && tempArray.length > 1) {
                const tempItem  = {
                  key: '1',
                  value: tempArray[1]
                }
                row.namedValueListOrigin.push(tempItem)
              }
               row.namedValueList = [...row.namedValueListOrigin as NamedListItem[]]    
              continue
            } 


            const temp: NamedListItem = {
              key: '0',
              value: ''
            }
            row.namedValueListOrigin = [] as NamedListItem[]
            row.namedValueListOrigin.push(temp)
            row.namedValueList = [...row.namedValueListOrigin as NamedListItem[]]    
            continue
          }
        }

        if ( row.feldTyp === "ComboBox" && row.namedValueListDataSourceName !== undefined && row.namedValueListDataSourceName.length > 0  ) {
            row.namedValueListOrigin = await ComboBoxDataSource.GetList(row.namedValueListDataSourceName, row.namedValueListFilterTyp ?? "", false)
            if ( row.namedValueListOrigin !== undefined) {
              row.namedValueList = [...row.namedValueListOrigin as NamedListItem[]]           
            }
        }

        if ( row.feldTyp === "ComboBoxDisplayValue" && row.namedValueListDataSourceName !== undefined && row.namedValueListDataSourceName.length > 0 ) {
          row.namedValueListOrigin = await ComboBoxDataSource.GetList(row.namedValueListDataSourceName, row.namedValueListFilterTyp ?? "", true)
          if ( row.namedValueListOrigin !== undefined) {
            row.namedValueList = [...row.namedValueListOrigin as NamedListItem[]]           
          }
        }
        
        if (row.feldTyp === "ObjectComboBox" && row.namedValueListDataSourceName !== undefined && row.namedValueListDataSourceName.length > 0 ) {
          row.namedValueListOrigin = await this.objectSearchDataSource.getList(row.namedValueListDataSourceName, "" , "", row.namedValueListFilterTyp ?? "")
          if ( row.namedValueListOrigin !== undefined) {
            row.namedValueList = [...row.namedValueListOrigin as ObjectListItems[]]

            let firstElement: ObjectListItems = {
              key: '',
              value: '',
              valueResourecId: '',
              resourceId: '',
              parentCascadeId: '',
              personProductNumber: '',
              kategorie: ''
            }

            if (row.namedValueList !== undefined ) {              
              row.list = []
              for( const element of row.namedValueList) {
                row.list.push(element)

                if ( row.namedValueListDataSourceName === "Patent" && firstElement.key === "") {
                  if ( editState && editState.patent && editState.patent.key && editState.patent.key.length > 0) {
                    if (editState.patent.key === element.key) {
                      firstElement = element as ObjectListItems
                    }
                  } else {
                    firstElement = element as ObjectListItems
                  }
                }
              }           
            }           
            
            if (row.namedValueListDataSourceName === "Patent" && firstElement.key !== "") {
              row.value = firstElement
              this.parentCascadeId = firstElement.key
              this.kategorie = firstElement.kategorie
              row.infoResourceId = firstElement.resourceId
              row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
              row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)
            }
                        

            if (! row.namedValueList || row.namedValueList.length == 0 ) {              
              row.list = {}
            }
          }

          if ( row.namedValueListDataSourceName === "FischereiRevier" && ( !this.parentCascadeId  || this.parentCascadeId.length < 1)) {
            const state: GeoState = {
              action: GeoStateAction.ChangeRevierAndType,
              wgs84X: undefined,
              wgs84Y: undefined,
              revierNumber: "100000",
              typ: "fischereirevier",
              lastState: false,
              isEditable: undefined

            }
            this.geoViewActionService.changeGeoState(state)
            isGeoDone = true
          }

          if ( row.namedValueListDataSourceName === "FischereiRevier" && this.parentCascadeId !== undefined && this.parentCascadeId.length > 0) {
            row.parentCascadeId = this.parentCascadeId
            row.kategorie = this.kategorie
            row.namedValueList = await this.objectSearchDataSource.search(row.namedValueListDataSourceName, row.parentCascadeId, row.kategorie, "")
            if ( row.namedValueList !== undefined && row.namedValueList.length > 0) {
              if ( editState && editState.fischereiRevier && editState.fischereiRevier.key && editState.fischereiRevier.key.length > 0) {
                for( let i = 0; i < row.namedValueList.length; i++ ) {
                  if ( editState.fischereiRevier.key === row.namedValueList[i].key) {
                    row.value = row.namedValueList[i]
                    row.infoResourceId = row.value.resourceId
                    row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
                    row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)
                  }
                }

              } else { 
                row.value = row.namedValueList[0]
                row.infoResourceId = row.value.resourceId
                row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
                row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)
              }
            }  
            if ( row.value.key && row.value.key.length > 0) {
              const state: GeoState = {
                action: GeoStateAction.ChangeRevierAndType,
                wgs84X: undefined,
                wgs84Y: undefined,
                revierNumber: row.value.key,
                typ: "fischereirevier",
                lastState: false,
                isEditable: undefined
              }
              this.geoViewActionService.changeGeoState(state)
              isGeoDone = true
            }            
          } 
          
          // Edit Feststellen
          if (!isGeoDone && row.namedValueListDataSourceName === "JagdRevier" || row.namedValueListDataSourceName === "Jagdrevier" ) {
            if( row.namedValueList !== undefined && row.namedValueList.length > 0 ) {
              if ( typeof(row.value) === "string" && !row.value || row.value.length < 1) {
                setTimeout( () => {
                if ( !row.namedValueList) return
                row.value = row.namedValueList[0]
                const state: GeoState = {
                  action: GeoStateAction.ChangeData,
                  wgs84X: x,
                  wgs84Y: y,
                  revierNumber: row.value.key,
                  typ: "jagdrevier",
                  lastState: false,
                  isEditable: undefined

                }
                GeoStoreData.Save(state)
                isGeoDone = true
                return isGeoDone
              }, 0)

              }
              else
              {
                if ( (! row.value || row.value === "") && row.namedValueList && row.namedValueList.length > 0 ) {
                  row.value = row.namedValueList[0]     
                  row.infoResourceId = row.value.resourceId
                  row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
                  row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)            
                }

                for( let i = 0; i < row.namedValueList.length; i++ ) {
                  if ( row.value === row.namedValueList[i].key) {
                    row.value = row.namedValueList[i]     
                    row.infoResourceId = row.value.resourceId
                    row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
                    row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)                               
                  }
                }
              }
              
              if (newForm) {

                if ( (! row.value || row.value === "") && row.namedValueList && row.namedValueList.length > 0 ) {
                  row.value = row.namedValueList[0]     
                  row.infoResourceId = row.value.resourceId
                  row.infoTextTitle = await Resource.getResourceInfoText(`${row.infoResourceId}.Title`)
                  row.infoTextContent = await Resource.getResourceInfoText(`${row.infoResourceId}.Content`)            
                }


                const state: GeoState = {
                  action: GeoStateAction.ChangeRevierAndType,
                  wgs84X: undefined,
                  wgs84Y: undefined,
                  revierNumber: row.value,
                  typ: "jagdrevier",
                  lastState: false,
                  isEditable: undefined

                }
                GeoStoreData.Save(state)
                isGeoDone = true
              } else { 
                if (row.value ) {
                  const state: GeoState = {
                    action: GeoStateAction.ChangeData,
                    wgs84X: x,
                    wgs84Y: y,
                    revierNumber: row.value,
                    typ: "jagdrevier",
                    lastState: false,
                    isEditable: undefined

                  }
                  GeoStoreData.Save(state)
                  isGeoDone = true
                } 
              }
            } 
          }

        }        

      }

      if(formData.view && (formData.viewId === "GenerelleEinstellungen") || formData.viewId === "AppEinstellungen") {
        const profile = await UserProfile.GetClientSettingsAsync()
        let language = "de"
        if ( profile && profile.language && profile.language.length > 0 ) {
          language = profile.language
        }
        for (const row of  formData.view) {
          if(row.formularFeldname === "Sprache") {
            if(row.namedValueListOrigin && row.namedValueListOrigin.length > 0) {
              for( const listRow of row.namedValueListOrigin) {
                if( listRow && listRow.key === language ) {
                  row.value = language
                  break
                }
              }
            }
          }
        }
      }

      return isGeoDone
  }

  public saveLocalForm(formData: DynamicDataSet): void {
    this._store.commit("dynamicformstore/addDataSet", formData);
  }



 
  private async getFormFromApi(apiLoadMethod: string, dataView: string, clientRouteName: string , id: string, externResult: DynamicRecord[] | undefined) : Promise<DynamicDataSet | null> {

    if ( dataView && dataView.length > 0 && dataView === "WebShop" ) {
      const userProfile = new UserProfile()
      if( ! (await (userProfile.GetProfileData())).online) {
        return null
      }
    }

    if ( dataView === "FischbuchEintragPachtrevier" || dataView === "FischbuchEintragPatentrevier" || dataView === "FischbuchEintragLinthkanal" 
          || dataView === "WildbuchEintragFallwild" || dataView === "WildbuchEintragJagd" || dataView === "WildbuchEintragBeobachtung" 
          || dataView === "WildbuchEintragBeobachtungSpez" || dataView === "Wildbuch"  || dataView === "Fischbuch" || dataView === "HomeFischerei"  ||    dataView === "HomeJagd"  
          ) {
      let offlineData =  await getOfflineForm(dataView)

      if ( externResult) {
        for(const sourceRow of externResult ) {
          for( const targetRow of offlineData.view ) {        
            if (sourceRow.value &&  sourceRow.formularFeldname === targetRow.formularFeldname ) {
              targetRow.value = sourceRow.value
            }
          }
        }
      }

      offlineData = await this.CorrectFormOnFormLoad(offlineData)  
      return offlineData
    }

    if( Navigate.IsLocked() ) {
      return null
    }

    const client = AsyncRestClient.Create(true, `Error.${dataView}.Load`);
    if (client.isFailure) return null;

    const result = await client
        .getValue()
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + apiLoadMethod, JSON.stringify(
          {
            userId: Identity.getIdentity().getValue().UserId,
            formName: dataView, 
            clientRouteName: clientRouteName,
            id: id,
            lastRefreshDate: await RefreshServerObjects.getLastFormRefreshDate()

          }));  

    if (result.isFailure) throw new Error(`${result.error}`);
    let data: DynamicDataSet = result.getValue() as unknown as DynamicDataSet;
    data = await this.CorrectFormOnFormLoad(data)  

    return data
  }

  

  public async CorrectFormOnFormLoad(formData: DynamicDataSet) {
      if (! formData || ! formData.view) 
        return formData

      formData.view.forEach(async row => {
        if (row.feldTyp && row.feldTyp === "ActionButton") {
          if ( ! row.layoutKlasse || row.layoutKlasse.length < 2) {
            row.layoutKlasse = "btn action__button_primary btn-block action__button"
          } else {
            if (row.layoutKlasse && row.layoutKlasse.indexOf("bg-primary.bg-gradient") >= 0) {
              row.layoutKlasse = "btn action__button_primary btn-block action__button"
            } else {
              row.layoutKlasse = `btn ${row.layoutKlasse} btn-block action__button`
            }
          }
        }

        if ( row.feldTyp && ( row.feldTyp === "CheckTextRight" || row.feldTyp === "Check" || row.feldTyp === "ValueCheck" ) ) {
          if ( row.value ) {
            row.value === 'True' ? row.value = 'true' : row.value = 'false' 
          }
        }
 
        if ( row.feldTyp && ( row.feldTyp === "CheckTextRightFix" ) ) {
          if ( row.value ) {
            row.value === 'True' ? row.value = 'true' : row.value = 'true' 
          }
        }
 

        if( row.formularFeldname === "ClientVersion" ) {
          const text = await Resource.getResourceText(row.resourceId) 
          row.resourceId = `${text} - Code: ${CodeVersion} Data: ${appVersionControl.currentDisplayVersion}`
        }


        })
      return formData
  }

  public saveLocalPage(formData: DynamicDataSet): void {
    try
    {
      let storeData = this.getLocalForm();   
      for( const storeRow of storeData.view) {
        for( const formRow of formData.view) {
          if (storeRow.formularFeldname === formRow.formularFeldname && storeData.teilFormularName === formRow.teilFormularName ) {
            if (formRow.value) {
              if (storeRow.value != formRow.value) {
                storeRow.value = formRow.value;
              }
            }
          }
        }
      }

     

      storeData = this.copyToReadonlyFields(storeData)
      this.saveLocalForm(storeData);
    }
    catch( e ) {
      Out.trace("DynamicFormData",e);
    }
  }

  private copyToReadonlyFields( data: DynamicDataSet ) : DynamicDataSet  {
    let iFkTierart = 0
    let iJagdrevier = 0
    let valueJagdrevier: any = undefined
    let valueFkTierart: any = undefined
    data.view.forEach(storeRow => {
    
      //__ *** Tierart *** __
      
      
      if ( storeRow.formularFeldname && storeRow.formularFeldname.indexOf("Tierart") >= 0 )  {
        if ( iFkTierart === 0 && storeRow.value && storeRow.value.length > 0) {
          valueFkTierart = storeRow.value          
        }

        if ( iFkTierart > 0 && valueFkTierart && valueFkTierart.length > 0) {
           storeRow.value = valueFkTierart
        }
        iFkTierart++
        
      }

      //__ *** Jagdrevier *** __

     
      
      if ( storeRow.formularFeldname === "Jagdrevier" || storeRow.formularFeldname === "JagdRevier" ) {
        if ( iJagdrevier === 0) {
          valueJagdrevier = storeRow.value
        }

        if ( iJagdrevier > 0) {
           storeRow.value = valueJagdrevier
        }
        iJagdrevier++
      }

    })

    return data
  }



  private MapLoadStructToSaveStruct(
    formData: DynamicDataSet,
    pages: Array<string>
  ): RemoteStoreDataSet {
    const storeSet: StoreDataSet = {} as StoreDataSet;
    storeSet.id = formData.id ?? "";
    storeSet.view = [];

    if (storeSet.id === undefined) {
      storeSet.id = "";
    }


    // XXX
    const temp: DynamicRecord[] = JSON.parse(JSON.stringify(formData.view));
    overwriteSerializedLocalDate(formData.view, temp )

    storeSet.view = temp.filter(row => {
      if (pages.indexOf("*") >= 0) {
        return true;
      }

      if (pages[0] == row.teilFormularName) {
        return true;
      }

      return false;
    });

    const resultSet: RemoteStoreDataSet = {} as RemoteStoreDataSet;
    resultSet.id = storeSet.id;
    resultSet.view = [];

    storeSet.view.forEach(row => {
      if (row.value !== undefined && row.value !== null) {
        if( row.feldTyp === "Date") {
          try {
            if ( row.dateValue && row.dateValue !== undefined) {
              try {
                row.value = new Date(row.dateValue).toString()
              } catch (e) {
                Out.error(e);
              }

            } else {
              row.value = "";
            }

          } catch (error) {
            Out.error(error)
          }
        }
       
        if (row.feldTyp !== "Label" && row.feldTyp !== "Space") {
          const record: DynamicSaveRecord = {
            formularFeldname: row.formularFeldname,
            value: row.value.toString()
          };
          resultSet.view.push(record);
        }
      }
    });
    return resultSet;
  }

  //**Save*****************************************Business Logik wegen Offline Forms einbauen **********************************************************
  //** Offline Forms werden immer Syncronisiert und nicht hier an den Service übertragen */
  // Die übertragunf erfolgt im Modul DynamicFormBusinessCases.ts
  public async saveRemoteForm(apiSaveMethod: string, dataView: string, pages: Array<string>, clientRouteName: string): Promise<ResponseDataSet> {
    const formData: DynamicDataSet = this.getLocalForm();
    this.changeValueBackForObjectList(formData)
    const dynamicGridRowParentChild = await this.businessCases.saveRemoteFormOverwriteFormWithBusinessCase(formData, clientRouteName)
    const storeSet = this.MapLoadStructToSaveStruct(formData, pages);
    if( formData.viewId.indexOf("Fisch") >= 0 || formData.viewId.indexOf("Fish") >= 0 || formData.viewId.indexOf("Wild") >= 0 ) {
      const specialResponse: ResponseDataSet = {
        id: '',
        state: 'ok',
        stateMessage: 'ok',
        processState: '',
        processStateMessage: '',
        isError: false,
        dynamicGridRowParentChild: dynamicGridRowParentChild
      }
      return specialResponse
    }
    const result = await this.saveFormToApi(apiSaveMethod, dataView, storeSet);
    this.saveUserState(formData, result);
    return await result;
  }

  public changeValueBackForObjectList( formData: DynamicDataSet): void {
    formData.view.every(row => {      
        if (row.value !== undefined && ( ! typeof(row.value === "string") && ! typeof(row.value === "Date"))) {
          try{
            const objectListItem = row.value as unknown as ObjectListItems
            if ( objectListItem !== undefined)
              row.value = objectListItem.key
          } catch(e) {
            Out.error(e)
          }
        }
    });
  }

  private async saveFormToApi(  apiSaveMethod: string, dataView: string, dataSet: RemoteStoreDataSet) : Promise<ResponseDataSet> {

    if( Navigate.IsLocked() ) {

      const result: ResponseDataSet = {
        id: '',
        state: '',
        stateMessage: '',
        processState: '',
        processStateMessage: '',
        isError: false
      }

      return result
  }


    if ( dataView && dataView.length > 0 && dataView === "WebShop" ) {
      const userProfile = new UserProfile()
      if( ! (await (userProfile.GetProfileData())).online) {
        const offlineTextTitle = await Resource.getResourceText(`Offline.NotAllowed.Title`)
        const offlineTextContent = await Resource.getResourceText(`Offline.NotAllowed.Content`)
        const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
        notficationState.changeNotificationState({isNotification: true, text:  offlineTextContent, title: offlineTextTitle})
        
        const result: ResponseDataSet = {
          id: '',
          state: 'nok',
          stateMessage: 'nok',
          processState: '',
          processStateMessage: '',
          isError: true
        }
        
        throw new Error("Error.saveFormToApi.Offline")
      }

    }

    const client = AsyncRestClient.Create(true, `Error.${dataView}.Save`);
    if (client.isFailure) throw new Error("Error.saveFormToApi");

    const result = await client
        .getValue()
        .exchangeForComponents<string, string>(process.env.VUE_APP_API + apiSaveMethod, JSON.stringify(
          {
            userId: Identity.getIdentity().getValue().UserId,
            formName: dataView, 
            saveItems: dataSet,
            lastRefreshDate: await RefreshServerObjects.getLastFormRefreshDate()
          }));  

    if (result.isFailure) throw new Error(`${result.error}`);
    const data: ResponseDataSet = result.getValue() as unknown as ResponseDataSet;
    return data
  }

  public saveUserState(
    formData: DynamicDataSet,
    response: ResponseDataSet
  ): void {
    if (!response.isError) {
      const userProfile: UserProfile = new UserProfile()
      userProfile.ChangeUserId(response.id);
    }
  }

  private restoreDateValues(row: DynamicRecord) : DynamicRecord
  {
    if ( row.dateValue) {
      try {
        
          if ( row.dateValue && row.dateValue !== undefined ) {
            row.value = ConvertDateToIsoString( row.dateValue )
            
          }
      } catch (error) {
        Out.error(error)
        }
    }

    return row
  }

  public allFieldsValid(pageName: string, formData: DynamicDataSet): boolean {
    let isValid = true
    formData.view.every(row => {
      if (row.teilFormularName === pageName) {
        if (row.isValidationError) {
          isValid = false
          return false;
        }
      }
      return true
    });
    return isValid;
  }

  public getValue(formularFeldname: string) : any {
    let result;
    const tempForm =  this.getLocalForm()
    tempForm.view.every(row => {
      if (
        row.formularFeldname.toLowerCase().trim() === formularFeldname.toLowerCase().trim()
      ) {
        result = row.value;
        return false
      }
      return true
    });
    return result;
  }

  public showHideFields(formData: DynamicDataSet, fieldNames: Array<string> , visible: boolean ) : DynamicDataSet {
    formData.view.forEach(row => {
      fieldNames.forEach(fieldName => {
        if ( fieldName === row.formularFeldname) {
          row.istSichtbar = visible
        }
      })
    });
    return formData
  }

  public enableDisableFields(formData: DynamicDataSet, fieldNames: Array<string> , enable: boolean ) : DynamicDataSet {
    formData.view.forEach(row => {
      fieldNames.forEach(fieldName => {
        if ( fieldName === row.formularFeldname) {
          row.istEditierbar = enable
        }
      })
    });
    return formData
  }

  public validateFields(
    pageName: string,
    formData: DynamicDataSet
  ): DynamicDataSet {
    let pw = "";
    let pwValidation = "";

    let fruehestensAb: Date | undefined = undefined
    let gueltigAb: Date | undefined = undefined

    formData.view.forEach(row => {
      if (row.teilFormularName === pageName) {
       
        row.validationMessage = "";
        row.isValidationError = false;
        row = this.restoreDateValues(row)
        row = this.validate(row);
        row = this.validateLength(row);
        if (row.feldTyp === "PasswordValidation") {
          pwValidation = row.value ?? "";
        }
        if (row.feldTyp === "Password") {
          pw = row.value ?? "";
        }

        try {

          if(pageName.indexOf("WebShopDaten") >= 0) {
        
            if(row.formularFeldname === "FruehestensAb") {
              if (row.value && row.value.length > 1) {
                fruehestensAb = getDateFromGermanStringDate(row.value)
                if (fruehestensAb) {
                  fruehestensAb = getDateWithoutTime(fruehestensAb)
                }
              }
            }

          
            if(row.formularFeldname === "GueltigAb") {
              gueltigAb = ConvertUtcStringToDateOrUndefined(row.value)
              if( fruehestensAb && gueltigAb ) {
                gueltigAb = getDateWithoutTime(gueltigAb)
                if (fruehestensAb.getTime() > gueltigAb.getTime())  {
                  row.validationMessage = "Frühestens ab: " + ConvertDateToDayString(fruehestensAb);
                  row.isValidationError = true;
                }
              }
            }
          }
        } catch(e) {
          Out.error(e)
        }

        if (pageName.indexOf("WildbuchEintrag") >= 0) {
        if (row.formularFeldname === "Datum") {
          if (row.value && row.value.length > 1) {
            let dt = ConvertUtcStringToDateOrUndefined(row.value)
            if (dt) {
              dt = getDateWithoutTime(dt)
              const dtNow = getDateWithoutTime(newDate())
              if (dt.getTime() > dtNow.getTime())  {
                row.validationMessage = Resource.getResourceTextSyncMode("Validation.Message.Zukunft","");
                row.isValidationError = true;
              }
            } else {
              row.validationMessage = Resource.getResourceTextSyncMode("Validation.Message.Falsch","");
              row.isValidationError = true;
            }
            
          }
        }
      }

      }
    });

    if ( !pw || pw.length < 1 || !pwValidation || pwValidation.length < 1 ) {
      formData.view.forEach(row => {
        if (row.feldTyp === "PasswordValidation" && row.istSichtbar) {
          row.validationMessage = Resource.getResourceTextSyncMode("Validation.Message.PasswordEmpty","");
          row.isValidationError = true;
        }
      });
      return formData;
    }

    if (pw !== "" && pwValidation !== "" && pw !== pwValidation) {
      formData.view.forEach(row => {
        if (row.feldTyp === "PasswordValidation" && row.istSichtbar) {
          row.validationMessage = Resource.getResourceTextSyncMode("Validation.Message.PasswordDifferent","");
          row.isValidationError = true;
        }
      });
    }
    return formData;
  }

  // xxx
  private validateLength(record: DynamicRecord): DynamicRecord { 
    if (record.tabellenName && record.tabellenName.indexOf("JagdAbgang") >= 0 && record.tabellenFeldname && record.tabellenFeldname.indexOf("AnzahlTiere") >= 0 ) {
      if ( record.maxLaenge > 0 && record.value != null && record.value != "" && record.value != undefined && !record.isValidationError) {
        try {
          const temp = parseInt(record.value)
          if ( !temp || Number.isNaN(temp)) return record
          if ( temp > record.maxLaenge ) {
            record.isValidationError = true;
            record.validationMessage = "";
          }
          return record
        } catch(e) {
          Out.noOperation("")
        }
      
      }
    }

    if(record.formularFeldname && record.formularFeldname === "KoordinateX") {
      if( record.maxLaenge && record.maxLaenge === -1) {
        record.isValidationError = true;
        record.validationMessage = "";
      }
      return record
    }

    if(record.formularFeldname && record.formularFeldname === "KoordinateY") {
      if( record.maxLaenge && record.maxLaenge === -1) {
        record.isValidationError = true;
        record.validationMessage = "";
      }
      return record
    }



    if ( record.maxLaenge > 0 && record.value != null && record.value != "" && record.value != undefined && !record.isValidationError) {
      if (record.feldTyp === "Space" || record.feldTyp === "Date") {
        return record;
      }

      if (record.feldTyp === "Check" || record.feldTyp === "CheckTextRight" || record.feldTyp === "Code" || record.feldTyp === "ValueCheck" ) {
        return record;
      }

      if (record.feldTyp === "CheckTextRightFix") {
        return record;
      }

      if (record.feldTyp === "Label") {
        return record;
      }

      if (record.value.length > record.maxLaenge) {
        record.isValidationError = true;
        record.validationMessage = Resource.getValidationMessage(record.resourceIdErrorMeldungMaxLaenge);
      }
    }
    return record;
  }

  private validate(record: DynamicRecord): DynamicRecord {
    record.isValidationError = false;
    record.validationMessage = "";


    if (!record.istEditierbar || !record.istSichtbar) {
      return record;
    }

    if (record.istPflichtfeld && (record.feldTyp === "Check" || record.feldTyp === "CheckTextRight" || record.feldTyp === "ValueCheck") ) {
      if ( record.value == null || record.value === "" || record.value === undefined || record.value === "false") {
        record.isValidationError = true;
        record.validationMessage =
        Resource.getValidationMessage( record.resourceIdErrorMeldungPflichtfeld ) ?? Resource.getResourceTextSyncMode("Validation.Message.Mandatory","");
        return record;
      }
    }

    if (record.istPflichtfeld && (record.feldTyp === "CheckTextRightFix") ) {
      if ( record.value == null || record.value === "" || record.value === undefined || record.value === "false") {
        record.isValidationError = true;
        record.validationMessage =
        Resource.getValidationMessage( record.resourceIdErrorMeldungPflichtfeld ) ?? Resource.getResourceTextSyncMode("Validation.Message.Mandatory","");
        return record;
      }
    }

    if (record.istPflichtfeld && (record.value == null || record.value == "" || record.value == undefined)) {
      record.isValidationError = true;
      record.validationMessage =
      Resource.getValidationMessage( record.resourceIdErrorMeldungPflichtfeld) ?? Resource.getResourceTextSyncMode("Validation.Message.Mandatory","");
      return record;
    }

    if (record.regulaererAusdruck != null && record.regulaererAusdruck.length > 2) {
      try {
        if ( !record.istPflichtfeld && ( !record.value || record.value.length < 1  )) return record

        const regex = new RegExp(record.regulaererAusdruck, "i");
        if (!regex.test(record.value)) {
          record.isValidationError = true;
          record.validationMessage =
          Resource.getValidationMessage(  record.resourceIdErrorMeldungValidierung ) ?? Resource.getResourceTextSyncMode("Validation.Message.Correct","");
        
          return record;
        }
      } catch (e) {
        // eslint-disable-next-line
        const t = e;
      }
    }
    return record;
  }
}

import { ComboBoxDataSource } from "@/components/Shared/Dynamicform/ComboBoxDataSource"
import { Version, addOrReplaceRecord, getRecord, getRecordRaw } from "../database/asyncDb"
import { ConvertCanonicalToDate, ConvertDateToIsoString, ConvertUtcStringToDateTime, GetDateDiffMinutes } from "../functional/datetimehelper"
import { ModalDialogType, openDialog } from "../observables/ModalDialogNotification"
import { Resource, ResourceText } from "../resource/resource"
import { isAllSynced } from "../serviceworker-related/Sync"
import { Out } from "../frontent/clientMessage"
import { UserProfile } from "../backend/userProfile"
import { CodeVersion, StartVersion } from "@/business/AppConst"


export type AppVersion = {   
    isServiceWorkerUpdateAvailable: boolean

    versionUpdatedAt : string 
    
    serviceWorkerUpdatedAt: string 
    serviceWorkerActivatedAt?: string
    
    currentVersion: string 
    newVersion: string 
    
    codeVersion: string
    currentDisplayVersion: string ,
    newDisplayVersion: string ,
    committedVersion: string

    
    serviceWorkerDelayetAt: string 


    tempId: string

    tempCategory: string 

    UpdateIvokedAt: string 

    UpdateCounter: number

    IsUpdate: boolean

}

export const appVersionControl: AppVersion = {
    isServiceWorkerUpdateAvailable: false,

    versionUpdatedAt: "",

    serviceWorkerUpdatedAt: "",
    serviceWorkerActivatedAt: "",    

    currentVersion: "1.01",
    newVersion: "",

    currentDisplayVersion: "1.01",
    newDisplayVersion: "",

    serviceWorkerDelayetAt: "",

    codeVersion: CodeVersion,

    tempId: "",

    tempCategory: "",

    UpdateIvokedAt: "",

    UpdateCounter: 0, 

    committedVersion: "", 

    IsUpdate: true




}

export class VersionControl {

    public async hasUnsyncedData() :Promise<boolean> { 
        const isSynced = await isAllSynced()
        return !isSynced
    }

    public async showUnsyncedDataDialog(store: any) {
        if (!(await UserProfile.GetCurrentProfile()).online) return
        const title = await Resource.getResourceText("UpdateApp.UnsyncedData.Titel")
        const content = await Resource.getResourceText("UpdateApp.UnsyncedData.Content")
        openDialog(store, {name: "UnyncedData", isOk: true, isCancel: false, titel: title , text: content, open: true, type: "info"})
    }

    public async isOnline() :Promise<boolean> {
        if ((await UserProfile.GetCurrentProfile()).online) return true

        return false
    }

    public async shopUpdateDialog(store: any) {       
        if (!(await UserProfile.GetCurrentProfile()).online) return
        openDialog(store,{name: Resource.getResourceUpdateAppText(ResourceText.UpdateModalDialogName) , isCancel: true, isOk: true, open: true, titel:await Resource.getResourceText("UpdateApp.Version.Titel") , text: await Resource.getResourceText("UpdateApp.Version.Content"), type: ModalDialogType.VersionUpdate})
    }

    public async serviceWorkerUpdateInvoked() :Promise<void> {
        const succeeded = await this.ensureVersionControl()
        appVersionControl.isServiceWorkerUpdateAvailable = true
        appVersionControl.serviceWorkerUpdatedAt = ConvertDateToIsoString(new Date())
        appVersionControl.serviceWorkerDelayetAt = ""
        appVersionControl.serviceWorkerActivatedAt = ""
        if( succeeded)  await this.saveVersionControl()
    }

    public async updateDone() :Promise<void> {
        try {
            appVersionControl.committedVersion = "x.xx"
            const succeeded = await this.ensureVersionControl()
            appVersionControl.serviceWorkerActivatedAt = ConvertDateToIsoString(new Date())
            appVersionControl.isServiceWorkerUpdateAvailable = false
            appVersionControl.serviceWorkerDelayetAt = ""
            appVersionControl.currentVersion = appVersionControl.newVersion
            appVersionControl.currentDisplayVersion = appVersionControl.newDisplayVersion
            appVersionControl.versionUpdatedAt = ConvertDateToIsoString(new Date())
            appVersionControl.codeVersion = CodeVersion
            appVersionControl.UpdateIvokedAt = ""
            appVersionControl.UpdateCounter = 0
            appVersionControl.tempId = ""
            appVersionControl.tempCategory = ""
            appVersionControl.committedVersion = appVersionControl.newVersion
            appVersionControl.IsUpdate = true
            if( succeeded)  await this.saveVersionControl()
    
        } catch( e ) {
            console.error(`updateDone: ${e}`)
        }    
    }

    public async isNewVersionPending() :Promise<boolean> {
        const succeeded = await this.ensureVersionControl()
        if ( !appVersionControl.newVersion || appVersionControl.newVersion === "" || !appVersionControl.currentVersion || appVersionControl.currentVersion === "" || appVersionControl.currentVersion === "1.01") {
            await this.SaveVersionInfo()
            return false
        }

       

        const codeVersionNumer = this.getFloat(CodeVersion)
        const newVersionNumber = this.getFloat(appVersionControl.newVersion)
        const currentVersionNumber = this.getFloat(appVersionControl.currentVersion)

        console.error(`isNewVersionPending: ${codeVersionNumer} + ${newVersionNumber} + ${currentVersionNumber} : ${CodeVersion} - ${StartVersion} - ${appVersionControl.newVersion} - ${appVersionControl.committedVersion} - ${appVersionControl.isServiceWorkerUpdateAvailable} - ${appVersionControl.UpdateIvokedAt}`)

        if ( appVersionControl.newVersion && appVersionControl.newVersion !== "" && newVersionNumber === codeVersionNumer 
        && CodeVersion === StartVersion && ( !appVersionControl.committedVersion || appVersionControl.committedVersion === "") && !appVersionControl.isServiceWorkerUpdateAvailable ) {           
            await this.setUpdateFirstInstallInvoked()
            console.error(`isNewVersionPending: ${appVersionControl.UpdateIvokedAt}`)
            return true;
        }

        if ( appVersionControl.newVersion && appVersionControl.newVersion !== "" && newVersionNumber === codeVersionNumer && appVersionControl.committedVersion !== CodeVersion) {           
            if (!(await UserProfile.GetCurrentProfile()).online) return false            
            return true
        }

        if (  appVersionControl.newVersion && appVersionControl.newVersion !== "" && newVersionNumber > currentVersionNumber) {
            if ( !appVersionControl.isServiceWorkerUpdateAvailable ) return false
            if (!(await UserProfile.GetCurrentProfile()).online) return false
            return true
        } 


        return false
    }

    private getFloat(stringDecimal: string | undefined) : number {

        if ( !stringDecimal) return 0

        try {
            return parseFloat(stringDecimal ?? "0")
        } catch( e ) {
            return 0
        }
    }

    public async isUpdateDelayed() :Promise<boolean> { 
        const succeeded = await this.ensureVersionControl()            
        if ( !appVersionControl.serviceWorkerDelayetAt || appVersionControl.serviceWorkerDelayetAt === "") return false

        const delayed = ConvertUtcStringToDateTime(appVersionControl.serviceWorkerDelayetAt)
        if( GetDateDiffMinutes(delayed, new Date()) > 5 ) {
            appVersionControl.serviceWorkerDelayetAt = ""
            if( succeeded)  await addOrReplaceRecord(Version, {id:"version", "versionLocal": JSON.parse(JSON.stringify( appVersionControl)) })
            return false
        }
        return true
    }

    public async setUpdateDelayed() :Promise<void> {
        const succeeded = await this.ensureVersionControl()
        appVersionControl.serviceWorkerDelayetAt = ConvertDateToIsoString(new Date())
        if( succeeded)  await this.saveVersionControl()    
    }

    public async setUpdateInvoked() :Promise<void> {
        const succeeded = await this.ensureVersionControl()
        appVersionControl.UpdateIvokedAt = ConvertDateToIsoString(new Date())
        if( succeeded)  await this.saveVersionControl()    
    }

    public async setUpdateFirstInstallInvoked() :Promise<void> {
        const succeeded = await this.ensureVersionControl()
        appVersionControl.UpdateIvokedAt = ConvertDateToIsoString(new Date())
        appVersionControl.UpdateCounter = 1
        if( succeeded)  await this.saveVersionControl()    
    }


    public async isUpdateWithoutDialog() :Promise<boolean> {
        try {
            const succeeded = await this.ensureVersionControl()
            if ( !appVersionControl.UpdateIvokedAt || appVersionControl.UpdateIvokedAt === "") return false
            appVersionControl.UpdateCounter++
            if( succeeded)  await this.saveVersionControl()    
            if ( appVersionControl.UpdateCounter === 2) return true
    
        } catch( e ) { 
            console.error(`isUpdateWithoutDialog: ${e}`)
        }

        return false

    }

    public async setTemp(id: string | undefined , category: string | undefined) :Promise<void> {
        const succeeded = await this.ensureVersionControl()
        if ( !id ) id = ""
        if ( !category ) category = ""

        appVersionControl.tempId = id ?? ""
        appVersionControl.tempCategory = category ?? ""
        if( succeeded)  await this.saveVersionControl()    
    }

    public async getTempId() :Promise<string | undefined> {
        const succeeded = await this.ensureVersionControl()
        return appVersionControl.tempId
    }

    public async getTempCategory() :Promise<string | undefined> {
        const succeeded = await this.ensureVersionControl()
        return appVersionControl.tempCategory
    }

   
    public async getDisplayVersion() :Promise<string> {
        try {
            const succeeded = await this.ensureVersionControl()
            appVersionControl.codeVersion = CodeVersion        
            if( succeeded) await this.saveVersionControl()    
            return (appVersionControl.currentDisplayVersion ?? "" )    
        } catch( e ) {
            return appVersionControl.codeVersion ?? "1.01"
        }
    }

    public async isOldVersion() :Promise<boolean> {
        try {
            const temp  =  await getRecord(Version, "version")
            if ( !temp || !temp.versionLocal || temp.versionLocal === "") {
                return true
            }

            if( !temp.versionLocal && temp.currentVersion && temp.currentVersion === "1.01" ) return true
        } catch( e ) {
            return true
        }
        return false
    }

    public async SaveVersionInfo() {
        try {

            const temp = (await  ComboBoxDataSource.GetValue("SystemParameter", "AppVersion")) ?? ""
            if ( !temp || temp === "" ) return
    
            const rawResult = temp.split(",")
            if ( rawResult.length != 2 ) return
            if( !rawResult[0] || !rawResult[1] ) return
    
            const succeeded = await this.ensureVersionControl()
            if( parseFloat(rawResult[0]) < parseFloat(appVersionControl.currentVersion ?? "0") ) return
    
            appVersionControl.newVersion = rawResult[0]
            appVersionControl.newDisplayVersion = rawResult[1]
    
    
            if ( appVersionControl.currentVersion === "1.01") {
                appVersionControl.currentVersion = appVersionControl.newVersion
                appVersionControl.currentDisplayVersion = appVersionControl.newDisplayVersion               
            }
        
    
            appVersionControl.codeVersion = CodeVersion
    
            if( succeeded)  await this.saveVersionControl()    
        } catch( e ) {
            console.error(`SaveVersionInfo: ${e}`)
        }    
    }


    public async setNewVersion() : Promise<void> {
        try {            
            const temp  =  await getRecordRaw(Version, "version")
            if ( temp && temp.versionLocal && temp.versionLocal.currentVersion && temp.versionLocal.currentVersion !== "") {
              console.log(`isNewVersion: false`)
              return 
            }
        } catch( e ) {
            console.log(`isNewVersion: Error (true)`)
        }
        console.log(`isNewVersion: true`)
        await this.ensureVersionControl()
        appVersionControl.committedVersion = CodeVersion
        appVersionControl.versionUpdatedAt = ConvertDateToIsoString(new Date())
        appVersionControl.IsUpdate = false
        await this.saveVersionControl()
    }

    private async ensureVersionControl() : Promise<boolean> { 
        try {            
            const temp  =  await getRecordRaw(Version, "version")
            if ( temp && temp.versionLocal) {
                this.mapAppVersion( temp.versionLocal as AppVersion )
            }
        } catch( e ) {
            return false
        }

        await this.saveVersionControl()
        return true
    }


    // ************************* Private Methods *************************
    private async saveVersionControl() : Promise<void> {
        try {

            if ( appVersionControl.committedVersion === "" ) {
                
                try {            
                    const temp  =  await getRecordRaw(Version, "version")
                    if ( temp && temp.versionLocal) {
                        const result = temp.versionLocal as AppVersion
                        if( result ) {
                            appVersionControl.committedVersion = result.committedVersion
                            appVersionControl.versionUpdatedAt = result.versionUpdatedAt
                            appVersionControl.IsUpdate = result.IsUpdate
                        }
                    }
                } catch( ex ) {
                    console.error(`saveVersionControl: ${ex}`)
                }
        

            }

            await addOrReplaceRecord(Version, {id:"version", "versionLocal": JSON.parse(JSON.stringify( appVersionControl)) })
        } catch( e ) {
           console.error(`saveVersionControl: ${e}`)
        }
    }

    private mapAppVersion( version: AppVersion) {
        appVersionControl.isServiceWorkerUpdateAvailable = version.isServiceWorkerUpdateAvailable
        appVersionControl.versionUpdatedAt = version.versionUpdatedAt
        appVersionControl.serviceWorkerUpdatedAt = version.serviceWorkerUpdatedAt
        appVersionControl.serviceWorkerActivatedAt = version.serviceWorkerActivatedAt
        appVersionControl.currentVersion = version.currentVersion
        appVersionControl.newVersion = version.newVersion
        appVersionControl.newDisplayVersion = version.newDisplayVersion
        appVersionControl.currentDisplayVersion = version.currentDisplayVersion
        appVersionControl.serviceWorkerDelayetAt = version.serviceWorkerDelayetAt
        appVersionControl.codeVersion = version.codeVersion
        appVersionControl.tempId = version.tempId
        appVersionControl.tempCategory = version.tempCategory
        appVersionControl.UpdateIvokedAt = version.UpdateIvokedAt
        appVersionControl.UpdateCounter = version.UpdateCounter
        appVersionControl.committedVersion = version.committedVersion
        appVersionControl.IsUpdate = version.IsUpdate
    }



}
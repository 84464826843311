
import { onMounted, onUnmounted, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import { StateNavigationAction } from "@/business/application/statemachine/StateInterfaces";
import { StateLogin } from "@/business/application/statemachine/concrete/StateLogin";
import { changeNavigation } from "@/infrastructure/observables/NavStateNotification";

import { showError } from "@/infrastructure/observables/ModalDialogNotification";

import { AsyncRestClient } from "@/infrastructure/backend/async/AsyncRestClient";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { Resource } from "@/infrastructure/resource/resource";
import { getNavigationStateFromMessage, NavigationMessage } from "@/business/application/RuleCaclulation/RuleCalculationLoginFirstUse"
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import { Identity } from "@/infrastructure/backend/userIdentity";
import { Out } from "@/infrastructure/frontent/clientMessage";
import { ComboBoxDataSource } from "../Shared/Dynamicform/ComboBoxDataSource";
import { ObjectSearchDataSource } from "../Shared/Dynamicform/ObjectSearchDataSource";
import { LoaderStateServiceForRoot } from '@/infrastructure/observables/loaderState';
import { OfflineForms } from '../Shared/Dynamicform/DynamicFormPreload';
import { DynamicGridRequest } from '../Shared/dynamicList/DynamicListTypes';
import { getDynamicGridFromApi } from '@/infrastructure/serviceworker-related/DynamicGridServiceWorker';
import { deleteUserContent } from "@/infrastructure/database/asyncDb";
import { FishingEdit } from "../fishing/business/FishingEdit";
import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";
import { TierartRules } from "@/infrastructure/serviceworker-related/TierartZusatzService";
import { RefreshServerObjects } from "@/infrastructure/resource/RefreshServerObjects";
import { LogoutChannelForRoot } from "@/infrastructure/observables/logoutChannel";
import { InitialStartRequest} from "@/infrastructure/backend/async/InitialStartRequest"
import { RefreshLoginWhenResourceLoaded } from "@/infrastructure/frontent/RefreshLoginWhenResourceLoaded";
import { isAppInstalled } from "@/infrastructure/frontent/InstalledAsPwa";
import { RemoteProdukt } from '@/components/webshop/RemoteProdukt';
import { AsyncDbVersion } from "@/infrastructure/version/asyncDbVersion";
import { VersionControl } from "@/infrastructure/version/VersionControl";

//PasswordEmailConfirm
export default {
  components: {},
  name: "UserLogin",
  emits: ["userError"],
  inheritAttrs: false,
  setup() {
    let unsubscribe:any = {};

    const loginUri = "api/authentication/login"

    AsyncDbVersion.ensureCurrentDbVersion()

    Identity.isUserRole()

    interface ILoginData {
      username: string,
      password: string
    }

    const loginData: ILoginData = {
      username: process.env.VUE_APP_USERNAME,
      password: process.env.VUE_APP_PASSWORD
    }

    
    const loginInformation = reactive(loginData);

    const passwordType = ref("password");
    const passwortIcon = ref("img/icons/eye-slash.svg");
    const store = useStore();

    let stateMachine: any = undefined
   
    const componentResource = reactive({
      efjTitel: "",
      benutzerName: "",
      password: "",
      login: "",
      registrierungLink: "",
      passwordVergessen: ""
    })

     function isPwaX() :boolean {
        Out.noOperation()
        Out.noOperation()
        return true
      }

    function isPwa() :boolean {
      const isApp = isAppInstalled()
      if ( isApp) return true
      const notficationState = NotificationStateServiceForRoot.getNotificationStateService()    
      notficationState.resetNotificationState()
      notficationState.changeNotificationState({
        isNotification: true, text:  Resource.getResourceTextSyncMode("App.NotInstalledAsPwa.Content", ""), title: Resource.getResourceTextSyncMode("App.NotInstalledAsPwa.Title", "")
      })
      return false
    }

    const refreshLanguageService = RefreshLoginWhenResourceLoaded.getRefreshLoginService()
    const refreshLanguageServiceSubscription = refreshLanguageService.subscription().subscribe({
      next: (value) => {
        if( value.refresh) {
          loadSyncResources()
          refreshLanguageServiceSubscription.unsubscribe()
        }
      }
    })


    async function loadSyncResources() {
      const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        componentResource.efjTitel = await Resource.getResourceTextOrDefault("eFj.App.Titel","Fischerei- und Jagdverwaltung")
        componentResource.benutzerName = await Resource.getResourceTextOrDefault("Login.LoginDaten.BenutzerName","Benutzername")
        componentResource.password = await Resource.getResourceTextOrDefault("Login.LoginDaten.Password","Password")
        componentResource.login = await Resource.getResourceTextOrDefault("Login.LoginDaten.Login","Login")
        componentResource.registrierungLink = await Resource.getResourceTextOrDefault("Login.LoginDaten.RegistrierenLink","Registrieren")
        componentResource.passwordVergessen = await Resource.getResourceTextOrDefault("Login.LoginDaten.PasswordVergessen","Passwort vergessen")

        //await checkAndOpenDialogForNewVersion()
    }
  


    async function loadSyncResourcesOld() {
      const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
      
      const language = (await UserProfile.GetClientSettingsAsync()).language
      if ( language === "fr") {
        componentResource.efjTitel = await Resource.getResourceTextOrDefault("eFj.App.Titel","fr-Fischerei- und Jagdverwaltung")
        componentResource.benutzerName = await Resource.getResourceTextOrDefault("Login.LoginDaten.BenutzerName","fr-Benutzername")
        componentResource.password = await Resource.getResourceTextOrDefault("Login.LoginDaten.Password","fr-Password")
        componentResource.login = await Resource.getResourceTextOrDefault("Login.LoginDaten.Login","fr-Login")
        componentResource.registrierungLink = await Resource.getResourceTextOrDefault("Login.LoginDaten.RegistrierenLink","fr-Registrieren")
        componentResource.passwordVergessen = await Resource.getResourceTextOrDefault("Login.LoginDaten.PasswordVergessen","fr-Passwort vergessen")

      } else {
        componentResource.efjTitel = await Resource.getResourceTextOrDefault("eFj.App.Titel","Fischerei- und Jagdverwaltung")
        componentResource.benutzerName = await Resource.getResourceTextOrDefault("Login.LoginDaten.BenutzerName","Benutzername")
        componentResource.password = await Resource.getResourceTextOrDefault("Login.LoginDaten.Password","Password")
        componentResource.login = await Resource.getResourceTextOrDefault("Login.LoginDaten.Login","Login")
        componentResource.registrierungLink = await Resource.getResourceTextOrDefault("Login.LoginDaten.RegistrierenLink","Registrieren")
        componentResource.passwordVergessen = await Resource.getResourceTextOrDefault("Login.LoginDaten.PasswordVergessen","Passwort vergessen")
      }
    }
  
    loadSyncResources()





    async function tryLogin() {
        const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
        const userProfile = new UserProfile()
        loaderState.changeLoaderState({isInProgress: true})
        //await ensureStart()

        if ( !isPwa() ) {
          loaderState.changeLoaderState({isInProgress: false})
          return 
        }


        const startResult = await InitialStartRequest.Start( process.env.VUE_APP_API + "api/authentication/start" , "Login" )
        //if( !startResult ) return


        const unsyncedLogin = await userProfile.hasUnsyncedData()
        await userProfile.resetExpired()
        if ( !unsyncedLogin) {
          await RefreshServerObjects.resetLogin()
        }
        //await ComboBoxDataSource.initNamedLists(true)
        //await OfflineForms.initOfflineForms()
        const client = AsyncRestClient.Create(true);
        if (client.isFailure) return;

        const loginResult = await client
            .getValue()
            .exchangeForComponents<string, string>(process.env.VUE_APP_API + loginUri, JSON.stringify(loginData));  

        if (loginResult.isFailure) return;
        const navigationMessage = loginResult.getValue()

        let isSameUserId = false
        const identity = Identity.getIdentityViaCookie()
        if ( unsyncedLogin) {
          isSameUserId = await userProfile.appUserEqualCookieUser(identity.getValue().UserId )
          if ( !isSameUserId ) {
            const logout = LogoutChannelForRoot.getService()
            if (! logout.logoutState.cleanUp ) logout.changeLogoutState({cleanUp: true, login: true}) 
            return
          }
        }


        await userProfile.ChangeLastLoginDate() 

        const version = new VersionControl()
        await version.setNewVersion()

        if (identity) {                 
          try {
            if( !unsyncedLogin) {
              await deleteUserContent() 
              await FishingEdit.reset()
              await userProfile.ChangeUserId(identity.getValue().UserId)
              await userProfile.ChangeRoles(identity.getValue().Roles)
              await loadFishbook()

            } else {
                await FishingEdit.reset()
                await userProfile.resetUnsyncedData()
                await userProfile.ChangeUserId(identity.getValue().UserId)
                await userProfile.ChangeRoles(identity.getValue().Roles)
            }
          } catch( e ) {
            Out.noOperation(e)
          }
        }

        RemoteProdukt.resetJahresWechsel()

        await notifyUser(navigationMessage as NavigationMessage)

        stateMachine = StateMachineServiceLocator.get();
        stateMachine.setStartState(new StateLogin());
        stateMachine.getCurrentResultState();

        stateMachine.next(
            {
              navigation: StateNavigationAction.Jump,
              //conditions: [{ nameId: "*", value: "Home" }],
              conditions: [{ nameId: "*", value: getNavigationStateFromMessage(navigationMessage as NavigationMessage) }],
            },
              { 
                lookahead: false 
              }
            );
        changeNavigation(store);
    }

    // async function ensureStart() {
    //   const startClient = AsyncRestClient.Create(true);
    //     if (startClient.isFailure) return;
    //      const startResult = await startClient
    //         .getValue()
    //         .exchangeForComponents<string, string>(process.env.VUE_APP_API + startUri, JSON.stringify({appName: "eFJ"}));   
       
    // }

    async function loadFishbook() {
      const request1: DynamicGridRequest = {
                userId: Identity.getIdentity().getValue().UserId,
                viewId: "fishBookEntriesWithDetail",
                activeData: true,
                pageSize: 1,
                revierNr: "",
                routeName: "FishBook",
                detailRouteName: "FishbookDetailRecord",
                page: "FischbuchEintragPachtDaten",
                form: "FischbuchEintragPacht",
                language: (await UserProfile.GetClientSettingsAsync()).language,
                detailId: 'FishbookDetailRecord',
                detailList: true,
                allRecords: true,
                lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                lastViewIdRefreshState: await RefreshServerObjects.getLastGridFishBookRefreshDate()
            }
        const gridObject1 = await getDynamicGridFromApi(request1, true)
        const request2: DynamicGridRequest = {
                userId: Identity.getIdentity().getValue().UserId,
                viewId: "personProductsWithDetail",
                activeData: true,
                pageSize: 1,
                revierNr: "",
                routeName: "",
                detailRouteName: "",
                page: "1",
                language: (await UserProfile.GetClientSettingsAsync()).language,
                detailId: '',
                detailList: false,
                allRecords: true,
                lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                lastViewIdRefreshState: await RefreshServerObjects.getLastGridProductsRefreshDate()
            }
            
        const gridObject2 = await getDynamicGridFromApi(request2, true)

        
        const request3: DynamicGridRequest = {
                userId: Identity.getIdentity().getValue().UserId,
                viewId: "wildBookEntriesWithDetail",
                activeData: true,
                pageSize: 1,
                revierNr: "",
                routeName: "",
                detailRouteName: "",
                page: "1",
                language: (await UserProfile.GetClientSettingsAsync()).language,
                detailId: '',
                detailList: false,
                allRecords: true,
                lastRefreshDate: await RefreshServerObjects.getLastGridFormRefreshDate(),
                lastViewIdRefreshState: await RefreshServerObjects.getLastGridWildBookRefreshDate()
            }
            
        const gridObject3 = await getDynamicGridFromApi(request3, true)

    }


    async function notifyUser(message: NavigationMessage) : Promise<void> {
      const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
      switch( message) {
        
        // __ login successful
        case "UserExistence.RefreshUserData":
          notficationState.resetNotificationState()
          notficationState.changeNotificationState({
            isNotification: true, text: await Resource.getResourceText("UserExistence.RefreshUserData.Content"), title:await Resource.getResourceText("UserExistence.RefreshUserData.Title")
          })
          break 
        case "UserExistence.PrivacyPolicy":
          notficationState.resetNotificationState()
          notficationState.changeNotificationState({
            isNotification: true, text: await Resource.getResourceText("UserExistence.PrivacyPolicy.Content"), title:await Resource.getResourceText("UserExistence.PrivacyPolicy.Title")
          })
          break
        case "UserExistence.ValidButWrongPassword":
          notficationState.resetNotificationState()
          notficationState.changeNotificationState({
            isNotification: true, text: await Resource.getResourceText("UserExistence.ValidButWrongPassword.Content"), title:await Resource.getResourceText("UserExistence.ValidButWrongPassword.Title")
          })
        break

        // migration
        case "UserExistence.RegistrationLogin":
          notficationState.resetNotificationState()
          notficationState.changeNotificationState({
            isNotification: true, text: await Resource.getResourceText("UserExistence.RegistrationLogin.Content"), title:await Resource.getResourceText("UserExistence.RegistrationLogin.Title")
          })
          break
        // case "UserExistence.ExistsButNoAccess":
        //   notficationState.resetNotificationState()
        //   notficationState.changeNotificationState({
        //     isNotification: true, text: await Resource.getResourceText("UserExistence.ExistsButNoAccess.Content"), title:await Resource.getResourceText("UserExistence.ExistsButNoAccess.Title")
        //   })
        //   break          
        case "UserExistence.ActivationByEmailInput":
          notficationState.resetNotificationState()
          notficationState.changeNotificationState({
            isNotification: true, text: await Resource.getResourceText("UserExistence.ActivationByEmailInput.Content"), title:await Resource.getResourceText("UserExistence.ActivationByEmailInput.Title")
          })
          break
        case "UserExistence.Known":
          notficationState.resetNotificationState()
          notficationState.changeNotificationState({
            isNotification: true, text: await Resource.getResourceText("UserExistence.Known.Content"), title:await Resource.getResourceText("UserExistence.Known.Title")
          })
          break          
        case "UserExistence.Activation":
          notficationState.resetNotificationState()
          notficationState.changeNotificationState({
            isNotification: true, text: await Resource.getResourceText("UserExistence.Activation.Content"), title:await Resource.getResourceText("UserExistence.Activation.Title")
          })
          break
      }
    }


    function passwordForgotten() {

      if ( !isPwa() ) {
          const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
          loaderState.changeLoaderState({isInProgress: false})
          return 
      }

      
    

      stateMachine = StateMachineServiceLocator.get();
      stateMachine.setStartState(new StateLogin());
      stateMachine.getCurrentResultState();
      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: [{ nameId: "*", value: "WaitForEmailConfirm" }],
        },
        { lookahead: false }
      );
      changeNavigation(store);
    }

    function registerUserLink() {

      if ( !isPwa() ) {
          const loaderState = LoaderStateServiceForRoot.getLoaderStateService()
          loaderState.changeLoaderState({isInProgress: false})
          return 
        }


      stateMachine = StateMachineServiceLocator.get();
      stateMachine.setStartState(new StateLogin());
      stateMachine.getCurrentResultState();

      stateMachine.next(
        {
          navigation: StateNavigationAction.Jump,
          conditions: [{ nameId: "*", value: "RegistrationLogin" }],
        },
        { lookahead: false }
      );
      changeNavigation(store);
    }



    function togglePassword() {
      if (passwordType.value === "password") {
        passwordType.value = "text";
        //passwortIcon.value = "img/icons/visibility-24px.svg";
        passwortIcon.value = "img/icons/eye.svg";
      } else {
        passwordType.value = "password";
        //passwortIcon.value = "img/icons/visibility_off-24px.svg";
        passwortIcon.value = "img/icons/eye-slash.svg";
      }
    }

    async function navigate(condition: any) {
      if (condition === "") {
        return;
      }
      const navigation = StateNavigationAction.Next;
      const conditions = [];
      conditions.push({ nameId: "*", value: condition });

      stateMachine = StateMachineServiceLocator.get();
      stateMachine.setStartState(new StateLogin());
      stateMachine.getCurrentResultState();


      stateMachine.next(
        {
          navigation: navigation,
          conditions: conditions
        },
        {
          lookahead: false
        });
      changeNavigation(store);
    }

    onMounted(() => {
      unsubscribe = store.subscribe((mutation: any) => {
        if (mutation.type === "navigationState/clickNavigationItem1") {
          navigate("");
        }
        if (mutation.type === "navigationState/clickNavigationItem2") {
          navigate("");
        }
        if (mutation.type === "navigationState/clickNavigationItem3") {
          navigate("Info");
        }
        if (mutation.type === "navigationState/clickNavigationItem4") {
          navigate("GeneralSettings");
        }
      });
    });

    onUnmounted(() => {
      unsubscribe();
    });


    return {
      ...toRefs(
        loginInformation,        
      ),
      tryLogin,
      //sendError,
      passwordType,
      togglePassword,
      passwortIcon,
      registerUserLink,
      passwordForgotten,
      componentResource
    };
  }
};


import { ref , defineComponent} from "vue";
import {
  closeDialog,
  getParameter
} from "@/infrastructure/observables/ModalDialogNotification";
import { useStore } from "vuex";
import { LightLog } from "@/business/log/LightLog";
import { sanitize } from "@/infrastructure/functional/sanitize";
import { Resource } from "@/infrastructure/resource/resource";

export default defineComponent ({
  name: "modal-popup",
  emits: {
    okClick: (message: string) => true,
    cancelClick: (message: string) => true
  },  
  components: {},
  props: {
          isVisible: {
            type: Boolean,
             default: true,
            require: false
          },  
  },
  setup(props, {emit}) {
    const store = useStore();
    const okClick = ref(true);
    const modalDialogTitle = ref("");
    const modalDialogText = ref("");
    const modalDialogName = ref("");
    const modalDialogType = ref("");
    const isVisible = ref<boolean>(props.isVisible)

    const modalDialogParameter = getParameter(store);

    modalDialogTitle.value = modalDialogParameter.titel;
    modalDialogText.value = modalDialogParameter.text;
    modalDialogName.value = modalDialogParameter.name;
    modalDialogType.value = modalDialogParameter.type;

    function doOk() {
      emit('okClick', modalDialogName.value);
      closeDialog(store);
    }

    function doCancel() {
      emit('cancelClick', modalDialogName.value);
      closeDialog(store);
    }

    function getLog() {
      return LightLog.getLogs();
    }

    function getModalDialogClass() {
      switch (modalDialogType.value) {
        case "info":
          return "efj__modal--info";
        case "error":
          return "efj__modal--error";
        case "warning":
          return "efj__modal--warning";
        case "log":
          return "efj__modal--log";
      }

      return "efj__modal--standard";
    }

    const logs = ref(getLog())
    const okTitle = ref("Ok")
    const cancelTitle = ref("Abbruch")

    async function load() {
        okTitle.value = await Resource.getResourceText(`Popup.Message.Ok`)
        cancelTitle.value = await Resource.getResourceText(`Popup.Message.Cancel`)      
    }


    const getModalDialogTitle = () => {
      if (modalDialogTitle?.value !== undefined)
        return sanitize(modalDialogTitle.value)
    }

    const getModalDialogText = () => {
      if ( modalDialogText?.value !== undefined)
        return modalDialogText.value

      // modalDialogText.value = "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
     
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"

      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"

      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"

      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      // modalDialogText.value += "Dies ist ein Text ein Text"
      return modalDialogText.value
    }

    load()


    return {
      okClick,
      doCancel,
      doOk,
      modalDialogTitle,
      modalDialogText,
      modalDialogType,
      getLog,
      logs,
      getModalDialogClass,
      getModalDialogTitle,
      getModalDialogText, 
      okTitle,
      cancelTitle
    };
  }
});


import { onMounted, onUnmounted, ref, reactive, defineComponent, computed, toRaw, onUpdated, watch, onRenderTracked } from "vue";
import { useStore } from "vuex";

import { DynamicFormData, DynamicDataSet, DynamicRecord } from "@/components/Shared/Dynamicform/DynamicFormData";
import { DynamicFormCommandHandler } from "@/components/Shared/Dynamicform/DynamicFormCommandHandler"

import { DynamicFormComboBox} from "@/components/Shared/Dynamicform/DynamicFormComboBox"
import { DynamicFormOnlineSearchComboBox } from "@/components/Shared/Dynamicform/DynamicFormOnlineSearchComboBox"
import { DynamicFormObjectSearchComboBox } from "@/components/Shared/Dynamicform/DynamicFormObjectSearchComboBox"


import { StateMachineServiceLocator } from "@/business/application/statemachine/StateMachine";
import {Resource, ResourceItem} from "@/infrastructure/resource/resource"

import { ComboBox } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import VueDatepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ErrorTypes, useRoute } from "vue-router";
import { NotificationStateServiceForRoot } from "@/infrastructure/observables/notficationState";
import NoDataRender from "../NoDataRender.vue";
import { DynamicFormNavigation } from "@/components/Shared/Dynamicform/DynamicFormCommands";
import { openDialog } from "@/infrastructure/observables/ModalDialogNotification";
import { RoutedButtonBarResponseChannelForRoot } from "../routed-button-bar/RoutedButtonResponseChannel";
import { RoutedButtonBarRequestChannelForRoot, RoutedButtonBarRequestState } from "../routed-button-bar/RoutedButtonBarRequestChannel";
import { DynamicDetailFormToDetailListState, DynamicFormToDetailListChannelForRoot } from "../dynamicListDetailList/Hooks/DynamicDetailFormToDetailListChannel";
import { Out } from "@/infrastructure/frontent/clientMessage";
import { DynamicSaveFormForRoot } from "./hooks/SaveFormData";
import { LocalStore } from "@/infrastructure/local-api/LocalStore"
import { DynamicTriggerSaveFormForRoot } from "./hooks/TriggerSaveForm";
import { LoaderStateServiceForRoot } from "@/infrastructure/observables/loaderState";
import { DynamicCloseForm } from "./hooks/CloseForm";
import { AppStateContext, disableButtonTime } from "@/business/AppConst";
import GeoMap from "@/components/Shared/geo/GeoMap.vue"
import { Gps } from "../geo/gps";
import { DynamicNavigation } from "@/components/Shared/dynamicNavigation/DynamicNavigation";
import { NavigateDynamicForm } from "../dynamicNavigation/Hooks/NavigateDynamicForm";
import { UnmountDynamicForm } from "@/infrastructure/observables/UnmountDynamicForm";
import {v4 as uuidv4} from 'uuid';
import { action } from "../dynamicNavigation/DynamicNavigationTypes";
import { DynamicLoadFromListData } from "./DynamicLoadFromListData";
import { GeoStoreData } from "../geo/hooks/GeoStoreData";
import { GeoView } from "../geo/hooks/ChangeGeoMap";
import { GeoViewListener } from "../geo/hooks/GeoMapListener";
import { TierartRules } from "@/infrastructure/serviceworker-related/TierartZusatzService";
import WarenKorbListe from "@/components/webshop/WarenKorbListe.vue"
import { LocalWarenkorb } from "@/components/webshop/LocalWarenkorb";
import { RemoteWarenKorb } from "@/components/webshop/RemoteWarenKorb";
import { NamedListItem } from "@/infrastructure/generics/namedListItem";
import { addDays, addMonths, addWeeks, getDateFromGermanStringDate, overwriteSerializedLocalDate } from "@/infrastructure/functional/datetimehelper";
import { UserProfile } from "@/infrastructure/backend/userProfile";
import { GeoValidationStore , geoValidationData} from "@/components/Shared/Dynamicform/DynamicFormGeoValidation"
import { ManageSubscriptions } from "@/infrastructure/frontent/ManageSubscriptions"
import { DynamicFormBusinessCases } from "./DynamicFormBusinessCases";
import { start } from "@popperjs/core";
import { getDependentComboBoxComunes, getDependentComboBoxWildlifePreserve } from "@/infrastructure/serviceworker-related/CascadeHuntigComboBoxes";

export default  defineComponent ({
  name: "DynamicForm",
  props: 
  {
    apiLoadMethod: {
      type: String,
      default: "",
      require: true
    },
    apiSaveMethod: {
      type: String,
      default: "",
      require: true
    },
    form: {
      type: String,
      default: "",
      require: true
    },
    page: {
      type: String,
      default: "",
      require: true
    },
    remoteId: {
      type: String,
      default: "",
      require: true
    },
    loadErrorTitle: {
      type: String,
      default: "",
      require: false
    },
    loadErrorMessage: {
      type: String,
      default: "",
      require: false
    },
    detailData: {
      type: Object,
      default: null,
      required: false
    },
    hiddenFields: {
      type: String,
      default: "",
      required: false
    },
    parentId: {
      type: String,
      default: "",
      require: false
    },   
    records: {
      type: Array,
      default: undefined,
      require: false
    },
    routeName: {
      type: String,
      default: undefined,
      require: false
    },
    mode: {
      type: String,
      default: "ApiMode",
      require: false
    },
    filter: {
      type: String,
      default: "",
      require: false
    },
    waitForResponse: {
      type: Boolean,
      default: false,
      require: false
    },
    geoEditMode: {
        type: String,
        default: "ReadOnly",
        require: false
    },
    // Allowed, OnStartup, Off
    geoGpsMode: {
        type: String,
        default: "Allowed",
        require: false
    },
    // additional, full
    geoDisplayClass: {
        type: String,
        default: "additional",
        require: false
    },
    geoCheckInsidePolygon: {
        type: Boolean,
        default: true,
        require: false
    }, 
    scrollPageClass: {
      type: String,
      default: "",
      require: false
    },
    geoCenterVisible: {
        type: Boolean,
        default:true,
        require: false
    },    

  },
  components: {VueDatepicker, ComboBox, NoDataRender, GeoMap, WarenKorbListe},
  inheritAttrs: false,
  setup(props) {

    const request = {
      apiLoadMethod: props.apiLoadMethod,
      apiSaveMethod: props.apiSaveMethod,
      form: props.form,
      page: props.page,
      remoteId: props.remoteId,
      detailData: props.detailData,
      parentId: props.parentId,
      records: props.records,
      mode: props.mode,
      geoDisplayClass: props.geoDisplayClass,
      geoCheckInsidePolygon: props.geoCheckInsidePolygon,
      geoEditMode: props.geoEditMode,
      geoGpsMode: props.geoGpsMode,
      filter: props.filter,
      geoCenterVisible: props.geoCenterVisible
    };

    function getLayoutClass(row: DynamicRecord) {
      if ( !row.layoutKlasse ) 
        return "m-container"

      if ( row.layoutKlasse.indexOf("m-container") > 0)
        return row.layoutKlasse

      return row.layoutKlasse + " m_container "
    }
     
    function getScrollPage() {
      if ( !props.scrollPageClass || props.scrollPageClass === "") {
        return "row g-0 d-flex scroll-page"
      }
      if ( props.scrollPageClass || props.scrollPageClass === "scrollPageComplete") {
        return "row g-0 d-flex scroll-page-complete"
      }
    }



    const isLoadedFromList = ref(false)
    const store = useStore();
    let unsubscribe:any = {};

    const blocked = ref<boolean>(false)

    const route=useRoute();    
    const clientRouteName = computed(() =>route.name?.toString())
    
    const contentText = ref("")
    const additionalText = ref("")

    const clientId = ref(uuidv4())
    DynamicNavigation.setClientFormId(request.page, clientId.value)

    const loaderState = LoaderStateServiceForRoot.getLoaderStateService()

    const listNoDataRender  = ref<string>("NoDataRender")
    const testValue = ref<string>("")

    const objectSearchComboBox = new DynamicFormObjectSearchComboBox()
    const onlineSearchComboBox = new DynamicFormOnlineSearchComboBox()
    const comboBox = new DynamicFormComboBox()

    const disabledActionButton = ref<boolean>(false)
    const geoViewActionService = GeoView.getService()


    const clientSettings = reactive({
      languageShort: "de",
      language: "de-De",
      okText: "Ok",
      cancelText: "Abbruch"
    })


    async function loadClientSettings() {
       clientSettings.languageShort = (await UserProfile.GetClientSettingsAsync()).language

       if ( clientSettings.languageShort === "de") {
        clientSettings.language = "de-DE"
        clientSettings.okText = await Resource.getResourceText("Formular.Datum.Ok")
        clientSettings.cancelText = await Resource.getResourceText("Formular.Datum.Abbruch")
       } else {
        clientSettings.language = "fr-FR"
        clientSettings.okText = await Resource.getResourceText("Formular.Datum.Ok")
        clientSettings.cancelText = await Resource.getResourceText("Formular.Datum.Abbruch")
       }
    }

    loadClientSettings()

   
    //--------- RxJs dynamic Navigation

     let navigationAction: action | undefined = undefined
    const dynamicNavigation = NavigateDynamicForm.getService()
    const onDynamicNavigation = dynamicNavigation.subscription().subscribe({
      next: async (value) => {
        try {
          if ( !value || !value.direction || !value.action ) return
          if ( value.direction === "from") return
          if (! DynamicNavigation.isClientFormId(request.page, clientId.value)) return
          
          navigationAction = value.action
          if ( value.action === "previous-notify") {
            Out.pusRouterOut("OnDynamicForm Previous" + request.page + " " + clientId.value)
            dynamicNavigation.navigate({direction: "from", action: navigationAction, result: true, page: undefined})
            dynamicNavigation.reset()
          }
          if ( value.action === "next-validate") {
            Out.pusRouterOut("OnDynamicForm Next" + request.page + " " + clientId.value)
            const dataSet = formCommands.nextPage(data.result, dynamicData, []) 

            if (dataSet && dataSet.tempTaskResult && dataSet.tempTaskResult.isSucceeded) {
              await formCommands.manageZusatzInfo(store)
              dynamicNavigation.navigate({direction: "from", action: navigationAction, result: true,  page: undefined})
              dynamicNavigation.reset()            
            } else {
              dynamicNavigation.navigate({direction: "from", action: navigationAction, result: false,  page: undefined})
              dynamicNavigation.reset()
            }          
          }
          if ( value.action === "RuleDynamicSave") {
            dynamicNavigation.navigate({direction: "from", action: navigationAction, result: true,  page: undefined})
            dynamicNavigation.reset()
          }
          if ( value.action === "RuleDynamicDelete") {
            dynamicNavigation.navigate({direction: "from", action: navigationAction, result: true,  page: undefined})
            dynamicNavigation.reset()
          }

          if ( value.action === "load-page") {
            data.result.teilFormularName = value.page
            const dataSet = formCommands.loadLocalPage(data.result, dynamicData)
            assignFieldResource()
            
          

            if (value.page &&  value.page?.indexOf("Lokalisierung") >= 0 ) {
              formCommands.reloadGeoData(data.result, dynamicData)
            }
          }
        } catch(e) {
          console.error("dynamicNavigation")
        }
      }
    })

    ManageSubscriptions.NewSubscription(onDynamicNavigation , "DynamicForm" , request.form)

    const triggerSave = DynamicTriggerSaveFormForRoot.getService(request.form)
    const onTriggerSave = triggerSave.subscription().subscribe({
      next: (value) => {
        try {
          if (value.trigger && value.formName !== undefined && request && request.form && data && data.result && data.result.view &&  value.formName === request.filter && value.formName !== '') {
            const dynamicSaveService = DynamicSaveFormForRoot.getService(request.form)         
            dynamicSaveService.changeFormState( { formName: request.form, rows: data.result.view, attachTo: request.form })
          }
        } catch(e) {
          console.error("triggerSave")
        }
      }
    })

    //--------- RxJs dynamic load for filters e.g. --------- end -------------

    const updateCombobox = ref<number>(1)

    function remoteComboSelectedChange(event: any) {
      let selectedValue = ""
      let dt: Date | undefined = undefined
      //let dauer: string | undefined = undefined
      for(const row of data.result.view) {
        // if (row.formularFeldname === "GueltigkeitsDauer" && row.value && row.value.length > 0) {
        //   dauer = row.value
        // }
        if ( row.formularFeldname === "GueltigKeitsListe") {
          selectedValue  =  event.value.value;
          dt = getDateFromGermanStringDate(selectedValue)
        }
      }

      if ( !dt ) return

      for( const row of data.result.view) {
        if (row.formularFeldname === "GueltigAb") {
          if (dt) {
            row.value = dt
            row.dateValue = dt
          }
        }
      }

      // if( !dauer) return

      // for( let row of data.result.view) {
      //   if (row.formularFeldname === "GueltigBis") {
      //     if (dt) {
      //        const result = getBisDatum(dt,dauer)
      //        if (result) {
      //         row.value = result
      //         row.dateValue = result
      //        }
      //     }
      //   }
      // }
    }

    function getBisDatum(dt: Date, value: string) : Date | undefined {
      const temp = value.split(".")
      if ( temp.length !== 2) return undefined

      let i: number | undefined
      try {
        i = parseInt(temp[1])
      } catch(e) {
        return undefined
      }

      if(i && temp[0] === "Monat") {
        return addMonths(dt, i)
      }

      if(i && temp[0] === "Woche") {
        return addWeeks(dt, i)
      }

      if(i && temp[0] === "Tag") {
        return addDays(dt, i)
      }

    }

    function comboSelectedChange(event: any) {

      for(let row of data.result.view) {
        if ( row.namedValueListDataSourceName === event.component.id) {
          try { 
            row.value = event.value.key
            if ( row.namedValueListDataSourceName && row.namedValueListDataSourceName?.indexOf("Tierart") >= 0  ) {
              if ( data.result.viewId.indexOf("WildbuchEintrag") >= 0 ) {
                TierartRules.handlePflichtfelder(data.result.viewId, data.result.view)                
              }
            }

            DynamicFormBusinessCases.handleNachsucheOnChange(data.result.viewId, event.component.id, event.value.resourceId, data.result.view)

          } catch(e) {
            row.value= ""
           
          }

        }
      }
    }

    function contains(row: DynamicRecord, search: string) : boolean {
      if (row && search && search.length > 0 && row.formularFeldname && row.formularFeldname.toLowerCase().indexOf(search.toLowerCase()) >= 0 ) {
        return true
      }
      return false
    }

    function getGpsSignal() {
      Gps.CalculateGps("")
    }

    function openInformation(title: string | undefined, content: string | undefined) : void {
      if ( title === undefined)
        title = ""
      if ( content === undefined)
        content = ""

      openDialog(store, {name: "Information", isOk: true, isCancel: false, titel: `${title}`, text: `${content}`, open: true, type: "info"})
    }

    function getDynamicFormMode() {
      if (request && request.records !== undefined && request.records.length > 0)  return "GridMode"
      if ( request.mode) return request.mode
      return "ApiMode"
    }

    function isInformationVisible( title: string | undefined, content: string | undefined) : boolean {
      if ( (title && title.length > 0)  || (content && content.length > 0) ) {
        return true
      }
      return false
    }

    function toggleDesignMode(e: any) {      
      setTimeout( () => {
        isDesignMode.value = ! isDesignMode.value
      } )
    }


    function togglePassword() {
      if (passwordType.value === "password") {
        passwordType.value = "text";
        passwortIcon.value = "img/icons/eye.svg";
      } else {
        passwordType.value = "password";
        passwortIcon.value = "img/icons/eye-slash.svg";
      }
    }

    function togglePasswordValidation() {
      if (passwordValidationType.value === "password") {
        passwordValidationType.value = "text";
        passwortValidationIcon.value = "img/icons/eye.svg";
      } else {
        passwordValidationType.value = "password";
        passwortValidationIcon.value = "img/icons/eye-slash.svg";
      }
    }

    function getKeyValuePair(row: DynamicRecord) {
      if ( row && row.value && row.namedValueList) {
        for( const record of row.namedValueList ) {
          if ( record.key === row.value) {          
            return record
          }
        }
      } 
    }

    function filterChanged( e: any) {      
      try {
        comboBox.filterChanged(e, filterBy, data.result)        
      } catch(e) {
        Out.noOperation()
      }
    }


    async function onlineFilterChanged( e: any) { 
      if(blocked.value) return
      blocked.value = true   
      await onlineSearchComboBox.onlineFilterChanged(e , data.result )   
      blocked.value = false          
    }

    async function onlineSelectionChanged(e: any) {     
      await onlineSearchComboBox.onlineSelectionChanged(e.component.id, data.result,e.value)      
    }

    async function selectObjectListFilterChanged( e: any) {    
      await objectSearchComboBox.onObjectListFilterChanged(e , data.result )             
    }

    async function onOpenComboBox(e: any) {
      console.log(e.component.id)
    }


    async function onCloseComboBox(e: any) {
      console.log(e.component.id)
    }


    async function openSelectionBox(e: any) {
      if ( e.component.id === "Patent") {
        for(let i=0; i < data.result.view.length; i++ ) {
          if (data.result.view[i].formularFeldname === "Fischereirevier") {
            data.result.view[i].istEditierbar = false
          }
        }
      }
      if ( e.component.id === "FischereiRevier") {
        for(let i=0; i < data.result.view.length; i++ ) {
          if (data.result.view[i].formularFeldname === "Patent") {
            data.result.view[i].istEditierbar = false
          }
        }
      }
      await objectSearchComboBox.onObjectListFilterChanged(e , data.result )
      
    }

    async function closeSelectionBox(e: any) {
      if ( e.component.id === "Patent") {
        for(let i=0; i < data.result.view.length; i++ ) {
          if (data.result.view[i].formularFeldname === "Fischereirevier") {
            data.result.view[i].istEditierbar = true
          }
        }
      }
      if ( e.component.id === "FischereiRevier") {
        for(let i=0; i < data.result.view.length; i++ ) {
          if (data.result.view[i].formularFeldname === "Patent") {
            data.result.view[i].istEditierbar = true
          }
        }
      }

      if ( e.component.id === "JagdRevier") {
        let jagdRevierrRow = undefined
        for(let i=0; i < data.result.view.length; i++ ) {          
          if (data.result.view[i].formularFeldname === "JagdRevier") {
            jagdRevierrRow = data.result.view[i]
          }
        }

        if ( !jagdRevierrRow) return

        for(let i=0; i < data.result.view.length; i++ ) {          
          if (data.result.view[i].formularFeldname === "Gemeinde") {
            getDependentComboBoxComunes(jagdRevierrRow, data.result.view[i])
            
          }
        }

        for(let i=0; i < data.result.view.length; i++ ) {          
          if (data.result.view[i].formularFeldname === "WildSchutzGebiet") {
            getDependentComboBoxWildlifePreserve(jagdRevierrRow, data.result.view[i])
            
          }
        }


      }

    }

    async function selectObjectListSelectionChanged(e: any) {     
      await objectSearchComboBox.selectObjectListSelectionChanged(e.component.id, data.result,e.value)  
    }

   
    const notficationState = NotificationStateServiceForRoot.getNotificationStateService()    
    if ( props.loadErrorMessage.length > 0 ) {
      notficationState.resetNotificationState()
      notficationState.changeNotificationState({isNotification: true, text: props.loadErrorMessage, title: props.loadErrorTitle})
    }
       
    const formCommands = new DynamicFormCommandHandler();
    const dynamicData = new DynamicFormData(clientRouteName.value ?? "", store);

    const geoRequest = reactive({
      editMode: request.geoEditMode,
      gpsMode: request.geoGpsMode,
      centerVisible: request.geoCenterVisible,
      checkInsidePolygon: request.geoCheckInsidePolygon,
      displayClass: request.geoDisplayClass,
      mapDivId: "mapDetail",      
    })

    const data = reactive({
      result: dynamicData.getLocalPage(request.page),      
    });
    
    const passwordType = ref("password");
    const passwordValidationType = ref("password");
    const passwortIcon = ref("img/icons/eye-slash.svg");
    const passwortValidationIcon = ref("img/icons/eye-slash.svg");

    const errorState = ref(false);
    const errorMessage = ref("");
    const errorStateValidation = ref(false);

    const stateMachine = StateMachineServiceLocator.get();

    data.result.id = request.remoteId;
    data.result.apiLoadMethod = request.apiLoadMethod;
    data.result.apiSaveMethod = request.apiSaveMethod;
    data.result.viewId = request.form;
    data.result.teilFormularName = request.page;

    const dynamicRowId = ref("");


    const resetCombobox = reactive({key: '', value: ''})
    

    const isDesignMode = ref<boolean>(false)
    const getDesignMode = () => {
      return isDesignMode.value
    }

    
    let actionDiasabledTimer = 10
    async function action(row: DynamicRecord) {
      disabledActionButton.value = true

      if ( row && row.formularFeldname === "RuleDynamicSave") {
        disabledActionButton.value = true
      } else {
        disableActionButtonTimeOut()
      }


      if (getDynamicFormMode() === "GridMode" || getDynamicFormMode() === "DynamicMode" ) {
        await dynamicAction(store,row,data.result,dynamicData, clientRouteName.value ?? "")
        disabledActionButton.value = false
        return
      }

      const commandNavigation = new DynamicFormNavigation()
      await commandNavigation.actionButton(store,row,data.result,dynamicData, clientRouteName.value ?? "")
      disabledActionButton.value = false
    }

    function disableActionButtonTimeOut() {
      actionDiasabledTimer = setTimeout( ()=> {
        disabledActionButton.value = false
      }, disableButtonTime)

    }

    async function dynamicAction(store: any, row: DynamicRecord, formDataset: DynamicDataSet,  dynamicData: DynamicFormData, clientRouteName: string) {
      const dynamicSaveService = DynamicSaveFormForRoot.getService(request.form)  
      const service = DynamicCloseForm.getService() 
      const commandNavigation = new DynamicFormNavigation()   
      switch (row.formularFeldname) {
        case "RuleDynamicSave": 
          if (getDynamicFormMode() === "GridMode") { 
            data.result.id = dynamicRowId.value
            await formCommands.saveDetailFormPayload( data.result, dynamicData, store, clientRouteName)
            if (! data.result.tempTaskResult?.isSucceeded) {
              break
            }

            if (clientRouteName === "WebShopDaten") {
              loaderState.changeLoaderState({isInProgress: true})
              const id = uuidv4()
              await LocalWarenkorb.AddProductItem(id , data.result.view )
              const result = await RemoteWarenKorb.proofWebShopProducts()
              if (!RemoteWarenKorb.isValid(result)) {
                const notficationState = NotificationStateServiceForRoot.getNotificationStateService()      
                loaderState.changeLoaderState({isInProgress: false})
                notficationState.changeNotificationState({
                isNotification: true, text: RemoteWarenKorb.getProofMessage(result) , title: await Resource.getResourceText("WebShop.Proof.Failed.Title")})
                await LocalWarenkorb.DeleteProductItem(id)
                break
              }
              loaderState.changeLoaderState({isInProgress: false})
                //LocalWarenkorb.AddProductItem(uuidv4() , data.result.view )
            }

            if ( isLoadedFromList.value )
              dynamicNavigation.navigate({action: row.formularFeldname , direction: "to" , page: request.page, result: true})
            service.changeFormState({close: true,  records: formDataset.view as DynamicRecord[]})
            service.changeFormState({close: false, records: formDataset.view as DynamicRecord[]})
            break;
          } 
          if ( getDynamicFormMode() === "DynamicMode") {
            dynamicSaveService.changeFormState( { formName: request.form, rows: data.result.view, attachTo: request.form })
          }
          break
        case "RuleDynamicChange": 
          commandNavigation.actionButton(store,row,data.result,dynamicData,clientRouteName)
          break
        case "RuleDynamicShouldDelete": 
          commandNavigation.actionButton(store,row,data.result,dynamicData, clientRouteName)
          break
        case "RuleDynamicDelete": 
          data.result.id = dynamicRowId.value
          commandNavigation.actionButton(store,row,data.result,dynamicData, clientRouteName)
          if ( data.result.viewId.indexOf("Wildbuch") >= 0 && isLoadedFromList.value )
              dynamicNavigation.navigate({action: row.formularFeldname , direction: "to" , page: request.page, result: true})
          service.changeFormState({close: true})
          service.changeFormState({close: false})
          break          
      }
    }

    function resetFilterValues() {
      for (let row of data.result.view) {
        if (row.namedValueListDataSourceName && row.namedValueListDataSourceName  !== "" )
        {
          row.value = "" 
          updateCombobox.value = updateCombobox.value + 1      
          continue
        }
        if( row.value && row.value != "" ){
          row.value = ""
        }
        if( row.dateValue){
          row.dateValue = undefined
        }
      }
    }

    async function loadDetail() {
      const temp = request.detailData as unknown as DynamicDataSet
      data.result.view = temp.view
      data.result.state = temp.state
      data.result.stateMessage = temp.stateMessage
      await formCommands.loadDetailFormGetPage(data.result, dynamicData, store);      
      await assignFieldResource()

    }

    async function loadFormPage(isDetailList: boolean) {
      if ( isDetailList) {
        await formCommands.loadFormGetPageFromList(request.records as DynamicRecord[], data.result, dynamicData, store); 
        await assignFieldResource()     
      } else {
        await formCommands.loadFormGetPage(data.result, dynamicData, store);      
        await assignFieldResource()      
      }
     

      const savedRows = LocalStore.GetForm(request.filter)
      if ( savedRows && data && data.result && data.result.view ) {
        for( const savedRow of savedRows) {
          for(let i=0; i < data.result.view.length; i++ ) {
            if ( savedRow.formularFeldname === data.result.view[i].formularFeldname) {
              data.result.view[i].dateValue = savedRow.dateValue
              data.result.view[i].value = savedRow.value
              break

            }
          }
        }
      }

      let isKarte = false
      for( const row of data.result.view) {
        if ( row.feldTyp === "Karte") {
          isKarte = true
          break
        }
      }

      if ( isDetailList || isKarte) {
        GeoStoreData.NotifyGeoView()
        GeoStoreData.Reset()
      }


    }

    async function assignFieldResource() {
      for (const row of data.result.view) {
        row.feldTitel = await Resource.getResourceText(row.resourceId);        
        if ( row.feldTitel === undefined || row.feldTitel === null || row.feldTitel.length < 1 ) {
          row.feldTitel = row.resourceId
        }

        let cascadeHuntigComboBoxes = false
        if ( row.formularFeldname === "Gemeinde" || row.formularFeldname === "WildSchutzGebiet") {
          cascadeHuntigComboBoxes = true
        }
        
        if ( cascadeHuntigComboBoxes) {
          let jagdRevierrRow = undefined
          for(let i=0; i < data.result.view.length; i++ ) {          
            if (data.result.view[i].formularFeldname === "JagdRevier") {
              jagdRevierrRow = data.result.view[i]
            }
          }

          if ( !jagdRevierrRow) return

          for(let i=0; i < data.result.view.length; i++ ) {          
            if (data.result.view[i].formularFeldname === "Gemeinde") {
              getDependentComboBoxComunes(jagdRevierrRow, data.result.view[i])              
            }
          }

          for(let i=0; i < data.result.view.length; i++ ) {          
          if (data.result.view[i].formularFeldname === "WildSchutzGebiet") {
            getDependentComboBoxWildlifePreserve(jagdRevierrRow, data.result.view[i])
            
          }
        }


        }

      }

    }

    function applyHiddenFields() {
      if (!props.hiddenFields || props.hiddenFields.length < 1) return false
      let isHidden = false
      if (!data || !data.result || !data.result.view ) return false
      for (const row of data.result.view) {        
        if ( row.formularFeldname && row.formularFeldname.length > 0 && props.hiddenFields.indexOf(row.formularFeldname) >= 0) {
          row.istSichtbar = false
          isHidden = true
        }        
       }
       return isHidden
    }


    async function saveResources() {
      const resources: ResourceItem[] = [{resourceId: "0", value: ""}]
      resources.pop()
      for (const row of data.result.view) {
          if ( row.resourceId !== undefined && row.resourceId !== null && row.resourceId.length > 0 ) {
            if ( row.feldTitel === undefined || row.feldTitel === null) {
              row.feldTitel = "";
            }
            resources.push({resourceId: row.resourceId, value: row.feldTitel as unknown as string})
          }
       }
       Resource.updateResourceText(resources)
    }

    async function load() {
      if ( props.waitForResponse ) loaderState.changeLoaderState({isInProgress: true})



      const listLoadData = DynamicLoadFromListData.getDynamicData()
      if ( listLoadData ) {
        isLoadedFromList.value = true
        request.form = listLoadData.form
        request.page = listLoadData.page
        request.remoteId = listLoadData.remoteId
        request.records = listLoadData.records
        request.filter = listLoadData.filter
        request.geoDisplayClass = listLoadData.geoDisplayClass
        data.result.id = request.remoteId
        data.result.viewId = request.form
        data.result.teilFormularName = request.page
        //DynamicLoadFromListData.reset()
      }

      if (request.apiLoadMethod !== "" ) {      
        
        await loadFormPage(listLoadData !== undefined)
         if ( props.waitForResponse ) loaderState.changeLoaderState({isInProgress: false})
         applyContentFields()
        return
      } 
      if (getDynamicFormMode() === "GridMode") {
        dynamicRowId.value = request.remoteId
        data.result.id = dynamicRowId.value
        await formCommands.loadFormFromPayload(request.form, request.records as unknown as DynamicRecord[], data.result, dynamicData, store);      
        await assignFieldResource()     
         if ( props.waitForResponse ) loaderState.changeLoaderState({isInProgress: false}) 
         applyContentFields()
        return
      }

      if( request.detailData !== null ) {
        await loadDetail()
         if ( props.waitForResponse ) loaderState.changeLoaderState({isInProgress: false})
         applyContentFields()
        return
      }
        await assignFieldResource()      

        if ( props.waitForResponse ) loaderState.changeLoaderState({isInProgress: false})
        applyContentFields()
    }

  


    function applyContentFields() {
      if (!data.result || !data.result.view) return
      for (const row of data.result.view) {    
        if (row.feldTyp === "ContentText") {
          if (row.value && row.value !== "")
            contentText.value = row.value;
        }
        if (row.feldTyp === "AdditionalText") {
          if (row.value && row.value !== "")
            additionalText.value = row.value;
        }
      }
    }

    load()
    applyHiddenFields()

    let locked = false

    const routedButtonBarRequest = RoutedButtonBarRequestChannelForRoot.getService()
    const loadState: RoutedButtonBarRequestState = {
        sourceRoute: clientRouteName.value,
        targetRoutes: {backTargetRoute: props.routeName , vorwardTargetRoute: undefined},
        command: "Back",
        topic: "FormDetail"
        }

    if (getDynamicFormMode() === "GridMode" && request.mode !== "NoNavigation" ) {        
      routedButtonBarRequest.changeRouteState(loadState)
      locked = true
    }

    const routeButtonBarResponse = RoutedButtonBarResponseChannelForRoot.getService()  
    const onRouteButtonBarResponse = routeButtonBarResponse.subscription().subscribe({
          next: (value) => {
            try {
              if ( request.filter !== "" && request.filter !== undefined && request.filter === value.topic && !locked ) {
                if ( value.command === "Save") {                  
                  const result = JSON.parse(JSON.stringify(data.result.view))
                  overwriteSerializedLocalDate(data.result.view, result)
                  LocalStore.AddForm(request.filter,result)                
                }
                if ( value.command === "Back") {
                  const savedRows = LocalStore.GetForm(request.filter)
                  if ( savedRows && data && data.result && data.result.view ) {
                    for( const savedRow of savedRows) {
                      for(let i=0; i < data.result.view.length; i++ ) {
                        if ( savedRow.formularFeldname === data.result.view[i].formularFeldname) {
                          data.result.view[i].dateValue = savedRow.dateValue
                          data.result.view[i].value = savedRow.value
                          break

                        }
                      }
                    }
                  }
                }
              }

              if ( request.filter === "" && value.targetRoute !== undefined && value.targetRoute === props.routeName && value.topic === "FormDetail" && !locked ) {
                setTimeout( () => {
                  onRouteButtonBarResponse.unsubscribe()
                  onClosed(value.command === "Save")
                } , 0)
              }
              locked = false
              } catch(e) {
                console.error("routeButtonBarResponse")
              }
            }
            
        })


    const formResultResponse = DynamicFormToDetailListChannelForRoot.getService()
    const onClosed = async (isSave: boolean) => {

      if ( isSave) {
        data.result.id = dynamicRowId.value
        await formCommands.saveDetailFormPayload( data.result, dynamicData, store, clientRouteName.value ?? "")
        if ( !data.result.tempTaskResult?.isSucceeded ) return
      }

      const result: DynamicDetailFormToDetailListState = {
        viewId: request.form,
        view: [],
        attachTo: props.routeName,
        isSave: isSave
        }
      formResultResponse.changeDetailFormState(result)
    }

    const serverValidation = (row: DynamicRecord) => {
      if ( row.resourceIdServerValidation && row.resourceIdServerValidation.length > 0) {
        return "serverValidation__text-error"
      } else {
        return "serverValidation__text-success"
      }
    }

 // xxx xxx xxx @@@ WildbuchEintragLokalisierungJagd  WildbuchEintragJagd
 
    GeoValidationStore.Reset()

    const geoListenerService = GeoViewListener.getService()
    const onGeoListenerService = geoListenerService.subscription().subscribe({
      next: (value) => {
        try {
          const geoData = GeoValidationStore.Reset()
          if ( value.revierNumber) {
            geoData.insideRevierBorders = value.isInsidePoligon ?? false
            if ( typeof(value.revierNumber) === typeof("string") ) {
              geoData.revierNumber = value.revierNumber
            } else {
              if( (value.revierNumber as any).key ) {
                geoData.revierNumber = (value.revierNumber as any).key
              }
            }
          }
         
          if ( value.lv95Y && value.lv95X) {
            for( const row of data.result.view) {
              if ( row.formularFeldname === "KoordinateX") {
                row.value = value.lv95X.toString()
                geoData.lv95X = value.lv95X
              }
              if ( row.formularFeldname === "KoordinateY") {
                row.value = value.lv95Y.toString()
                geoData.lv95Y = value.lv95Y
              }
              if ( value.revierNumber && row.formularFeldname === "JagdRevier") {
                if ( typeof(value.revierNumber) === typeof("string") && row.namedValueList) {
                  for( const item of row.namedValueList ) {
                    if ( item.key === value.revierNumber ) {
                      row.value = item
                    }
                  }
                } else {
                  row.value = value.revierNumber
                }
              }
            }          
          }
        } catch(e) {
                console.error("geoListenerService")
        }
      }
    })

    function comboBoxDisable(htmlId: string) : void {
    const field = document.querySelector(htmlId)
      if ( field) {
        field.setAttribute("disabled", "disabled")
      }
    }

    const unmountDynamicForm = UnmountDynamicForm.getService()
    const onUnmountDynamicForm = unmountDynamicForm.subscription().subscribe({
      next: (value) => {
        try {
          if ( ! value || ! value.unMount) return

          onDynamicNavigation.unsubscribe()
          geoViewActionService.reset()      
          onRouteButtonBarResponse.unsubscribe()
          onTriggerSave.unsubscribe()
          unsubscribe();
          try {
            clearTimeout( actionDiasabledTimer )
          } catch(e) {
            Out.noOperation("");
          }

          onUnmountDynamicForm.unsubscribe()
        } catch(e) {
          console.error("unmountDynamicForm")
        }
      }
    })

    onMounted(() => {
      const currentState = stateMachine.getCurrentResultState();
      const commandNavigation = new DynamicFormNavigation()      

      unsubscribe = store.subscribe(mutation => {
        if ( !DynamicNavigation.isNavigationEnabled()) {
          if (currentState.navigation) {
            try {
              DynamicNavigation.saveLastStaticNavigationState([currentState.navigation?.nav1,currentState.navigation?.nav2,currentState.navigation?.nav3,currentState.navigation?.nav4])
            } catch(e) {
              Out.noOperation("")
            }
          }
        }

        if (!DynamicNavigation.isAssigned("DynamicForm")) return       

        if (mutation.type === "navigationState/clickNavigationItem1") {
          if (request.records === undefined )
            commandNavigation.navigate(store,data.result,dynamicData,currentState.navigation?.nav1, clientRouteName.value ?? "");
        }
        if (mutation.type === "navigationState/clickNavigationItem2") {
          if (request.records === undefined )
            commandNavigation.navigate(store,data.result,dynamicData,currentState.navigation?.nav2, clientRouteName.value ?? "");
        }
        if (mutation.type === "navigationState/clickNavigationItem3") {
          if (request.records === undefined )
            commandNavigation.navigate(store,data.result,dynamicData,currentState.navigation?.nav3, clientRouteName.value ?? "");
        }
        if (mutation.type === "navigationState/clickNavigationItem4") {
          if (request.records === undefined )
            commandNavigation.navigate(store,data.result,dynamicData,currentState.navigation?.nav4, clientRouteName.value ?? "");
        }
      });
    });

    onUpdated(() => {
      const patent = document.querySelector("#Patent")
      if ( patent) {
        patent.setAttribute("disabled", "disabled")
      }
      const fischereiRevier = document.querySelector("#FischereiRevier")
      if ( fischereiRevier) {
        fischereiRevier.setAttribute("disabled", "disabled")
      }

      const GueltigKeitsListe = document.querySelector("#GueltigKeitsListe")
      if ( GueltigKeitsListe) {
        GueltigKeitsListe.setAttribute("disabled", "disabled")
      }


      

      comboBoxDisable("#WildbuchOrtsbeschreibung")
      //comboBoxDisable("#WildbuchZeit")
      comboBoxDisable("#WildbuchJagdBetriebsart")
      comboBoxDisable("#WildbuchTodesursache")
      comboBoxDisable("#WildbuchNachsuche")
      comboBoxDisable("#WildbuchTieralter")
      comboBoxDisable("#WildbuchTierGeschlecht")
      comboBoxDisable("#Vermarktung")
      comboBoxDisable("#Nachtsichthilfe")
      comboBoxDisable("#Nachtsichtzielhilfe")
    })

    onUnmounted(() => {
      //onDynamicNavigation.unsubscribe()
      geoViewActionService.reset()      
      //onRouteButtonBarResponse.unsubscribe()
      //onDynamicLoad.unsubscribe()
      onTriggerSave.unsubscribe()
      onGeoListenerService.unsubscribe()
      unsubscribe();
      try {
        clearTimeout( actionDiasabledTimer )
      } catch(e) {
        Out.noOperation("");
      }
    });

    return {      
      data,
      passwordType,
      passwordValidationType,
      passwortIcon,
      passwortValidationIcon,
      errorState,
      errorMessage,
      errorStateValidation,
      listNoDataRender,
      testValue,
      isDesignMode,
      updateCombobox,
      disabledActionButton,
      togglePassword,
      togglePasswordValidation,
      action,
      getDesignMode,
      saveResources,
      toggleDesignMode,      
      filterChanged,   
      onlineFilterChanged,
      onlineSelectionChanged,
      selectObjectListSelectionChanged,
      selectObjectListFilterChanged,
      openInformation,
      isInformationVisible,
      getKeyValuePair,
      resetFilterValues,
      comboSelectedChange,
      remoteComboSelectedChange,
      serverValidation,
      contains,
      getGpsSignal,
      geoRequest, 
      contentText,
      additionalText,
      getScrollPage,
      getLayoutClass,
      clientSettings,
      blocked,
      openSelectionBox,
      closeSelectionBox,
      onOpenComboBox,
      onCloseComboBox
    };
  }
});
